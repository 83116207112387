import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import useConfig from "../../../hooks/useConfig";
import moment from "moment";
import { Download } from "@mui/icons-material";
import HttpService from "../../../hooks/Https-services";
import PageRoutes, {
  APIRoutes,
  INSIDER_PLAN_ID,
} from "../../../utils/constants";
import { toast } from "react-toastify";
import DialogBox from "../../../Common/Component/DialogBox";
import { Link } from "react-router-dom";
import useCommonDetails from "../../../Common/Hooks/useCommonDetails";

const MatchCard = (props: any) => {
  const {
    active,
    isHighLight = false,
    thubmnails,
    isDownloadAll,
    el,
    subscriptionDetails,
    setIsDownloadAll,
  } = props;
  const configData = useConfig();
  const [currenClip, setCurrentClip] = useState<any>(null);
  const [isDownload, setIsDownload] = useState(false);
  const [open, setOpen] = React.useState(false);
  const userDetails = useCommonDetails();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [upNext, setUpNext] = useState({
    EVENT_TYPE: "",
    HOME_TEAM_NAME: "",
    AWAY_TEAM_NAME: "",
    DATE: "",
    PLAYER_POSITION: "",
    PLAYER_JERSEY_NUMBER: "",
    PLAYER_NAME: "",
    PLAYER_TEAM_NAME: "",
    PERIOD: "",
  });
  const handleDownloadClick = async (event: any) => {
    event.stopPropagation();
    try {
      if (currenClip?.Status == "COMPLETE" && currenClip?.DownloadUrl) {
        const a = document.createElement("a");
        a.href = currenClip?.DownloadUrl;
        a.target = "_blank";
        a.download = `${currenClip.TaskID}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        setIsDownloadAll(true);

        setIsDownload(true);

        const res = await HttpService.post(APIRoutes.DownloadClips, {
          item: {
            duration: el.duration,
            id: parseInt(el.assetId),
            offset: el.offset,
          },
          game_details: {
            away_team: upNext.AWAY_TEAM_NAME,
            game_date_time: moment(upNext.DATE),
            home_team: upNext.HOME_TEAM_NAME,
          },
          other_details: {
            clip_id: el.id,
            type: upNext.EVENT_TYPE?.split("_")?.join(" "),
          },
        });
        toast.info(
          "Your download has been requested.  You will receive an email when it is complete."
        );
        setIsDownloadAll(false);
      }
    } catch (error: any) {
      setIsDownload(false);
      setIsDownloadAll(false);
      toast.error(error.response.data.message);
    }
  };
  const filtereData = (data: any) => {
    const returnData = data?.payload?.filter((element: any) => {
      if (
        element.category === "EVENT_TYPE" ||
        element.category === "HOME_TEAM_NAME" ||
        element.category === "AWAY_TEAM_NAME" ||
        element.category?.toUpperCase() === "DATE" ||
        element.category === "PLAYER_POSITION" ||
        element.category === "PLAYER_JERSEY_NUMBER" ||
        element.category === "PLAYER_NAME" ||
        element.category === "PLAYER_TEAM_NAME" ||
        element.category === "PERIOD"
      ) {
        return element;
      }
    });

    const keyValuePairs = returnData?.map((obj: any) => {
      const pair: any = {};
      pair[obj.category?.toUpperCase()] = obj.value;
      return pair;
    });

    const singleObject = keyValuePairs?.reduce((result: any, item: any) => {
      const key = Object.keys(item)[0];
      const value = item[key];
      result[key] = value;

      return result;
    });

    setUpNext(singleObject);
  };
  const findCurrent = useCallback(() => {
    const clip = props.allDownloadVideo?.find(
      (element: any) => element?.ClipID == el.id
    );
    if (clip) {
      setCurrentClip(clip);
    } else {
      setCurrentClip(null);
    }
  }, [el.id, props.allDownloadVideo]);
  useEffect(() => {
    filtereData(el);
  }, [el]);
  useEffect(() => {
    findCurrent();
  }, [findCurrent]);
  return (
    <Box
      sx={{
        "&:hover": { cursor: "pointer" },
        border: active ? "2px solid red" : "",
      }}
    >
      <Box sx={{ border: "1px solid #ccc", bgcolor: "#f5f5f5", py: 0.5 }}>
        {isHighLight ? (
          <Stack
            width={1}
            direction={"row"}
            justifyContent={"space-between"}
            sx={{ px: 1 }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "14px",
                fontStyle: "normal",
                color: configData.primary,
                fontWeight: "700",
              }}
            >
              PERIOD {upNext?.PERIOD}
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "14px",
                fontStyle: "normal",
                color: configData.primary,
                fontWeight: "700",
              }}
            >
              {upNext?.EVENT_TYPE}
            </Typography>
          </Stack>
        ) : (
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "14px",
              fontStyle: "normal",
              color: configData.primary,
              fontWeight: "700",
            }}
          >
            {upNext?.EVENT_TYPE}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          border: "1px solid #ccc",
          bgcolor: configData.tertiary,
          padding: 1,
          // textAlign: "center",
          position: "relative",
          display: "flex",
          alignItems: "center",
          textAlign: "start",
          justifyContent: "space-between",
        }}
      >
        {isHighLight ? (
          <Typography
            fontWeight="700"
            sx={{
              textTransform: "none",
              fontSize: "13px",
              fontStyle: "normal",
            }}
          >
            {`${upNext?.PLAYER_JERSEY_NUMBER}   ${upNext?.PLAYER_NAME} (${upNext?.PLAYER_POSITION})`}
            <Typography
              fontWeight="600"
              sx={{
                textTransform: "none",
                fontSize: "12px",
                fontStyle: "normal",
              }}
            >
              {upNext?.PLAYER_TEAM_NAME}
            </Typography>
          </Typography>
        ) : (
          <Typography
            fontWeight="700"
            sx={{
              textTransform: "none",
              fontSize: "13px",
              fontStyle: "normal",
            }}
          >
            {`${upNext.AWAY_TEAM_NAME} VS ${upNext.HOME_TEAM_NAME}`}
            <Typography
              fontWeight="600"
              sx={{
                textTransform: "none",
                fontSize: "12px",
                fontStyle: "normal",
              }}
            >
              {moment(upNext.DATE).format("MMMM DD, YYYY")}
            </Typography>
          </Typography>
        )}

        {process.env.REACT_APP_FEATURE === "true" && (
          <IconButton
            color={currenClip?.Status == "COMPLETE" ? "success" : "primary"}
            disabled={
              currenClip?.Status === "SUBMITTED" ||
              currenClip?.Status === "ERROR" ||
              currenClip?.Status == "PENDING" ||
              isDownload ||
              (currenClip?.Status !== "COMPLETE" && isDownloadAll)
            }
            onClick={
              subscriptionDetails?.plan_type_id == INSIDER_PLAN_ID ||
              userDetails?.UserRole?.Permissions?.includes("download")
                ? handleDownloadClick
                : handleClickOpen
            }
          >
            <Download />
          </IconButton>
        )}

        <DialogBox
          buttonText={""}
          handleClose={handleClose}
          open={open}
          content={
            <Typography sx={{ textTransform: "none", fontStyle: "normal" }}>
              It looks like you're trying to initiate a download. Downloads are
              limited to subscribers of our Insider Plan. Head over to the{" "}
              <Link to={PageRoutes.Packages}>Plans</Link> tab to upgrade to the
              Insider Plan and then try your download again.
            </Typography>
          }
          buttonActionSecondary={handleClose}
          butttonTextSecondary={"Cancel"}
        />
      </Box>
    </Box>
  );
};

export default MatchCard;
