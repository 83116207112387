import {
  Box,
  Button,
  Grid,
  Pagination,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import HighlightPlayer from "./Player";
import useConfig from "../../hooks/useConfig";
import HttpService from "../../hooks/Https-services";
import PageRoutes, {
  APIRoutes,
  INSIDER_PLAN_ID,
  UserRoles,
} from "../../utils/constants";
import MatchCard from "./MatchCard";
import { useLocation, useNavigate } from "react-router-dom";
import useCommonDetails from "../../Common/Hooks/useCommonDetails";

const Highlights = () => {
  const playerRef: any = React.useRef(null);
  const [pageData, setPageData] = useState({} as any);
  const [planDetails, setPlanDetails] = useState({} as any);
  const [isSkeletonList, setIsSkeletonList] = useState(true);
  const [videoList, setVideoList] = useState<any[]>([]);
  const [currentVideo, setCurrentVideo] = useState({} as any);
  const configData = useConfig();
  const userDetails = useCommonDetails();
  const navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = React.useState<any>(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  const GetHighLights = useCallback(async () => {
    setIsSkeletonList(true);
    try {
      if (
        ((planDetails?.plan_type_id == INSIDER_PLAN_ID &&
          !planDetails?.subscription?.pause_collection) ||
          [
            UserRoles.HTP_ADMIN,
            UserRoles.LEAGUE_ADMIN,
            UserRoles.FREE_WATCH_D,
          ].includes(userDetails?.UserRoleID)) &&
        ["nahl", "dev", "ssm", "usah"].includes(configData.name)
      ) {
        const res = await HttpService.get(
          `${APIRoutes.GetWhizzards}?page=${page}&limit=${10}`
        );
        if (res) {
          const data = location.state?.highlight;
          const finalData = res?.data?.data?.wizzards;
          const findIndex = res?.data?.data?.wizzards?.findIndex(
            (el: any) => el?.ID === data?.ID
          );
          if (findIndex !== -1) {
            finalData.splice(findIndex, 1);
            finalData.unshift(data);
            setCurrentVideo(data);
          } else {
            setCurrentVideo(finalData[0]);
          }
          setVideoList(finalData);

          delete res?.data?.data?.wizzards;
          setPageData(res?.data?.data);
        }
        setIsSkeletonList(false);
      } else {
        setIsSkeletonList(false);
      }
    } catch (error) {
      setIsSkeletonList(false);
    }
  }, [
    configData.name,
    page,
    planDetails?.plan_type_id,
    planDetails?.subscription?.pause_collection,
    userDetails?.UserRoleID,
  ]);
  const GetUserSubscriptionData = useCallback(async () => {
    try {
      const res = await HttpService.get(APIRoutes.GetSubscriptionDetials);

      if (res?.data?.data) {
        setPlanDetails(res?.data?.data);
        return res?.data?.data;
      }
    } catch (error) {}
  }, []);
  useEffect(() => {
    GetUserSubscriptionData();
  }, [GetUserSubscriptionData]);
  useEffect(() => {
    GetHighLights();
  }, [GetHighLights]);
  return (
    <Box sx={{ p: 5 }}>
      {((planDetails?.plan_type_id == INSIDER_PLAN_ID &&
        !planDetails?.subscription?.pause_collection) ||
        [
          UserRoles.HTP_ADMIN,
          UserRoles.LEAGUE_ADMIN,
          UserRoles.FREE_WATCH_D,
        ].includes(userDetails?.UserRoleID)) &&
      ["nahl", "dev", "ssm", "usah"].includes(configData.name) ? (
        <Grid sx={{ my: 2 }} container spacing={2}>
          <Grid item xs={12} md={8}>
            <Box sx={{ backgroundColor: configData.tertiary }}>
              <Typography
                variant="h2"
                mb={1}
                sx={{
                  color: "white",
                  padding: "5px",
                  px: 4,
                  borderRadius: "4px 4px 0px 0px",
                  boxShadow: "0 .5rem 1rem rgba(0,0,0,0.15)",
                  background: `linear-gradient(110deg, ${configData.secondary} 50%, ${configData.primary} 50%)`,
                }}
              >
                {/* <SquareIcon sx={{fontSize:"10px",m:"10px",color:configData.primary}}/> */}
                Now Showing
              </Typography>

              <Box sx={{ mt: 1 }}>
                <HighlightPlayer
                  playerRef={playerRef}
                  currentVideoData={currentVideo}
                  setCurrentVideo={setCurrentVideo}
                  videoList={videoList}
                  pageData={pageData}
                  videoUrl={currentVideo?.HighlightUrl}
                  setPage={setPage}
                  page={page}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} width="100%">
            <Typography
              variant="h2"
              mb={1}
              sx={{
                color: "white",
                padding: "5px",
                px: 4,
                borderRadius: "4px 4px 0px 0px",
                boxShadow: "0 .5rem 1rem rgba(0,0,0,0.15)",
                background: `linear-gradient(110deg, ${configData.secondary} 50%, ${configData.primary} 50%)`,
              }}
            >
              {/* <SquareIcon sx={{fontSize:"10px",m:"10px",color:configData.primary}}/> */}
              Up Next
            </Typography>

            <Stack
              sx={{
                my: 1,
                mx: "auto",
                width: 1,
                justifyContent: "center",
              }}
              spacing={2}
            >
              <Pagination
                onChange={handleChange}
                page={page}
                sx={{
                  mb: 1,
                  mt: 1,
                  width: 1,
                  mx: "auto",
                  justifyContent: "center !important",
                }}
                count={pageData?.totalPage}
              />
            </Stack>

            <Box
              sx={{
                overflowY: "auto",
                maxHeight: { xs: "350px", lg: "790px" },
                mb: 2,
                mt: 0,
                textAlign: "center",
              }}
            >
              {isSkeletonList ? (
                <Stack spacing={2} sx={{ px: 2 }}>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={70}
                  />
                  <Skeleton
                    variant="rectangular"
                    height={70}
                    animation="wave"
                  />
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={70}
                  />
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={70}
                  />
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={70}
                  />
                </Stack>
              ) : (
                videoList?.map((el: any, index: any) => (
                  <Box
                    onClick={() => {
                      setCurrentVideo(el);
                    }}
                  >
                    <MatchCard active={currentVideo?.ID === el.ID} el={el} />
                    {/* <MatchCard
                        isDownloadAll={isDownloadAll}
                        setIsDownloadAll={setIsDownloadAll}
                        allDownloadVideo={allDownloadVideo}
                        subscriptionDetails={subscriptionDetails}
                        el={el}
                        active={activeClip == el?.id}
                        thubmnails={thubmnails[index]}
                      /> */}
                  </Box>
                ))
              )}
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Box sx={{ textAlign: "center" }}>
          <Button
            onClick={() => navigate(PageRoutes.Packages)}
            variant="contained"
          >
            Buy Insider Plan
          </Button>
        </Box>
      )}
    </Box>
  );
};
export default Highlights;
