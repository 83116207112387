import {
  faPeopleArrows,
  faSquarePollHorizontal,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chat, Remove, Add } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Typography,
  TextField,
  Tooltip,
  Button,
  Select,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
  createTheme,
  Pagination,
  CircularProgress,
  Stack,
  Tab,
  Tabs,
  useTheme,
  Badge,
} from "@mui/material";
import React, { useContext } from "react";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import ListIcon from "@mui/icons-material/List";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ThemeProvider } from "styled-components";
import HokeyPlayIngIcon from "../../../assets/icons/HokeyPlayingIcon";
import { CATEGORY } from "../../../utils/constants";
import GameCenterBoard from "../../GameCenter";
import MatchCard from "../../PersonalChannel/MatchCard";
import ChatComponent from "../ChatComponent";
import ClipsData, { formatTime } from "../ClipsData";
import HighLight from "../HightLight";
import WatchStyle from "../Watch.style";
import useConfig from "../../../hooks/useConfig";
import Login from "../WatchParty/Login/Login";
import PollComponent from "../PollComponent";
import styled from "@emotion/styled";
import LoadingContext from "../../../Common/Contex/LoadingContex";
import MatchCardv2 from "../../PersonalChannel/MatchCardv2";
interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: string;
  value: string;
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "red",
    color: "red",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      style={{ flexGrow: 1 }}
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ height: 1 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: string) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
const VideoPlayerSideBar = ({
  handleChangeIcon,
  activeIcon,
  connectRoom,
  checked,

  gameDetails,
  gameData,
  liveData,
  hightLighData,
  getDuration,
  setSearch,
  isEdit,
  clipsButton,
  search,
  currentClip,
  handleDurationChange,
  handleChangeClips,
  setCurrentClip,
  handleSubmitData,
  handleTabChangeAction,
  setIsEdit,
  handleDelete,
  clipsData,
  videoAway,
  subscriptionDetails,
  playerRef,
  GetClipsDetails,
  setIsDownLoadStarted,
  isDownloadStarted,
  jumpToTime,
  selectedTabAction,
  selectedTab,
  handleTabChange,
  isDownloadAll,
  thubmnails,
  videoList,
  videoClipsData,
  handleChange,
  handlePrevious,
  handleNext,
  page,
  pageData,
  isLoading,
  playerRefLinius,
  findCurrentClipTime,
  chatRoom,
  setIsDownloadAll,
  activeClip,

  allDownloadVideo,
}: any) => {
  const configData = useConfig();
  const theme = useTheme();
  const { pollsDetails, openColleps }: any = useContext(LoadingContext);
  return (
    <Box
      sx={{
        px: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        bgcolor: configData.primary,
        height: checked ? 1 : "740px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          position: "sticky",
          top: 0,
          bgcolor: configData.primary,
          zIndex: 100,
          justifyContent: {
            xs: "space-between",
            md: "flex-start",
          },
          alignItems: "center",
        }}
      >
        {" "}
        <Tabs
          value={Object.keys(activeIcon)?.[0]}
          onChange={(e, newValue) => {
            handleChangeIcon(newValue);
          }}
          sx={{
            ".MuiTab-root": {
              p: 0,
              minWidth: 0,
            },
            ".MuiTabScrollButton-root": {
              width: 20,
            },
          }}
          variant="scrollable"
          // scrollButtons
          // allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
        >
          <Tab
            value={"stats"}
            {...a11yProps("stats")}
            label={
              <Box sx={WatchStyle.ItemStyle}>
                <IconButton>
                  <ListIcon
                    sx={{
                      color: activeIcon?.stats ? "white" : "grey",
                      fontSize: "30px",
                    }}
                  />
                </IconButton>{" "}
                <Typography
                  sx={{
                    color: activeIcon?.stats ? "white" : "grey",
                    fontSize: "10px",
                  }}
                  variant="caption"
                >
                  STATS
                </Typography>
              </Box>
            }
          />
          <Tab
            value={"plays"}
            {...a11yProps("plays")}
            label={
              <Box sx={WatchStyle.ItemStyle}>
                <IconButton>
                  <WatchLaterOutlinedIcon
                    sx={{
                      color: activeIcon?.plays ? "white" : "grey",
                      fontSize: "30px",
                    }}
                  />
                </IconButton>{" "}
                <Typography
                  sx={{
                    color: activeIcon?.plays ? "white" : "grey",
                    fontSize: "10px",
                  }}
                  variant="caption"
                >
                  PLAYS
                </Typography>
              </Box>
            }
          />
          <Tab
            value={"highlight"}
            {...a11yProps("highlight")}
            label={
              <Box sx={WatchStyle.ItemStyle}>
                <IconButton>
                  <HokeyPlayIngIcon
                    sx={{
                      color: activeIcon?.highlight ? "white" : "grey",
                      fontSize: "30px",
                    }}
                  />
                </IconButton>{" "}
                <Typography
                  sx={{
                    color: activeIcon?.highlight ? "white" : "grey",
                    fontSize: "10px",
                  }}
                  variant="caption"
                >
                  HIGHLIGHTS
                </Typography>
              </Box>
            }
          />
          <Tab
            value={"chat"}
            {...a11yProps("chat")}
            label={
              <Box
                onClick={() => {
                  connectRoom();
                }}
                sx={WatchStyle.ItemStyle}
              >
                <IconButton>
                  <Chat
                    sx={{
                      color: activeIcon?.chat ? "white" : "grey",
                      fontSize: "30px",
                    }}
                  />
                </IconButton>{" "}
                <Typography
                  sx={{
                    color: activeIcon?.chat ? "white" : "grey",
                    fontSize: "10px",
                  }}
                  variant="caption"
                >
                  CHAT
                </Typography>
              </Box>
            }
          />
          <Tab
            value={"watch"}
            {...a11yProps("watch")}
            label={
              <Box sx={WatchStyle.ItemStyle}>
                <IconButton sx={{ py: 0.6 }}>
                  <FontAwesomeIcon
                    icon={faPeopleArrows}
                    style={{
                      color: activeIcon?.watch ? "white" : "grey",
                      fontSize: "22px",
                      marginBottom: "8px",
                      marginTop: "6px",
                    }}
                  />
                </IconButton>{" "}
                <Typography
                  sx={{
                    color: activeIcon?.watch ? "white" : "grey",
                    fontSize: "10px",
                  }}
                  variant="caption"
                >
                  WATCH
                </Typography>
              </Box>
            }
          />
          <Tab
            value={"action"}
            {...a11yProps("action")}
            label={
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                variant={!!pollsDetails?.Question ? "dot" : "standard"}
              >
                <Box sx={WatchStyle.ItemStyle}>
                  <IconButton sx={{ py: 0.6 }}>
                    <FontAwesomeIcon
                      icon={faSquarePollHorizontal}
                      style={{
                        color: activeIcon?.action ? "white" : "grey",
                        fontSize: "22px",
                        marginBottom: "8px",
                        marginTop: "6px",
                      }}
                    />
                  </IconButton>{" "}
                  <Typography
                    sx={{
                      color: activeIcon?.action ? "white" : "grey",
                      fontSize: "10px",
                    }}
                    variant="caption"
                  >
                    ACTION
                  </Typography>
                </Box>
              </StyledBadge>
            }
          />
        </Tabs>
      </Box>
      <TabPanel
        value={Object.keys(activeIcon)?.[0]}
        index={"stats"}
        dir={theme.direction}
      >
        <Box>
          {gameDetails?.stats_embed ? (
            <Box>
              {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
              <iframe
                src={gameDetails?.stats_embed}
                style={{
                  width: "100%",
                  height: "550px",
                }}
              ></iframe>{" "}
            </Box>
          ) : gameDetails?.game_id_tts != null ? (
            <Box>
              <GameCenterBoard gameData={gameData} liveData={liveData} />
            </Box>
          ) : (
            <Typography variant="body2">
              No data is available for this game.
            </Typography>
          )}
        </Box>
      </TabPanel>
      <TabPanel
        value={Object.keys(activeIcon)?.[0]}
        index={"plays"}
        dir={theme.direction}
      >
        <Box
          sx={{ p: 1, background: "lightGray", flexGrow: 0.98, height: "100%" }}
        >
          {clipsButton}
          {!isEdit && (
            <Box sx={{ py: 1 }}>
              <TextField
                size="small"
                placeholder="Filter"
                onChange={(e: any) =>
                  setSearch({
                    ...search,
                    plays: e.target.value,
                  })
                }
                autoComplete="off"
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#DADADA",
                    },
                  },
                  input: {
                    fontSize: "14px",
                    fontWeight: 600,
                  },
                }}
              />
            </Box>
          )}

          {isEdit ? (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 2,
                  alignItems: "center",
                }}
              >
                <TextField
                  type={"text"}
                  name="name"
                  onChange={handleChangeClips}
                  value={currentClip.name}
                  autoComplete="off"
                  size="small"
                  placeholder="Clip Name"
                  sx={{
                    input: {
                      color: "black",
                      fontSize: "14px",
                      fontWeight: 600,
                      textTransform: "capitalize",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#DADADA",
                      },
                    },
                  }}
                  defaultValue={currentClip?.name}
                  variant="outlined"
                />
                <Typography
                  variant="body2"
                  sx={{
                    fontStyle: "normal",
                    textTransform: "none",
                    whiteSpace: "nowrap",
                  }}
                >
                  Clip Length:{" "}
                  {currentClip?.end_time - currentClip?.start_time || 20} secs
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 1,
                  my: 2,

                  alignItems: "center",
                }}
              >
                <TextField
                  label="Start Time"
                  size="small"
                  disabled
                  sx={{
                    input: {
                      fontSize: "14px",
                    },
                    label: {
                      fontSize: "14px",
                    },
                  }}
                  value={formatTime(currentClip?.start_time)}
                />
                <TextField
                  label="End Time"
                  size="small"
                  disabled
                  value={formatTime(currentClip?.end_time)}
                  sx={{
                    input: {
                      fontSize: "14px",
                    },
                    label: {
                      fontSize: "14px",
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 1,
                  mt: 1,

                  alignItems: "center",
                }}
              >
                <Stack direction={"row"} alignItems={"center"}>
                  <Stack>
                    <Tooltip
                      title={
                        getDuration() <= 20
                          ? "Duration should be greater than 20 sec"
                          : "Increase start time by 1 second"
                      }
                      arrow
                      placement="left"
                    >
                      <span>
                        <IconButton
                          onClick={() => handleDurationChange(1, true)}
                          disabled={getDuration() <= 20 ? true : false}
                          sx={{
                            pb: 0,
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                          }}
                        >
                          <ArrowDropUpIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip
                      title={
                        getDuration() >= 60
                          ? "Duration should be less than 1 min"
                          : "Decrease start time by 1 second"
                      }
                      arrow
                      placement="left"
                    >
                      <span>
                        <IconButton
                          onClick={() => handleDurationChange(-1, true)}
                          disabled={getDuration() >= 60 ? true : false}
                          sx={{
                            pt: 0,
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                          }}
                        >
                          <ArrowDropDownIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Stack>
                  <Stack direction={"row"} gap={0.5}>
                    <Tooltip
                      title={
                        getDuration() >= 60
                          ? "Duration should be less than 1 min"
                          : "Decrease start time by 10 seconds"
                      }
                      arrow
                      placement="top"
                    >
                      <span>
                        <Button
                          variant="outlined"
                          sx={{ px: 0.5, minWidth: 0 }}
                          disabled={getDuration() >= 60 ? true : false}
                          onClick={() => handleDurationChange(-10, true)}
                        >
                          <Remove sx={{ fontSize: "16px" }} />
                          10
                        </Button>
                      </span>
                    </Tooltip>
                    <Tooltip
                      title={
                        getDuration() <= 20
                          ? "Duration should be greater than 20 sec"
                          : "Increase start time by 10 seconds"
                      }
                      arrow
                      placement="top"
                    >
                      <span>
                        <Button
                          variant="outlined"
                          disabled={getDuration() <= 20 ? true : false}
                          onClick={() => handleDurationChange(10, true)}
                          sx={{ px: 0.5, minWidth: 0 }}
                        >
                          10
                          <Add sx={{ fontSize: "16px" }} />
                        </Button>
                      </span>
                    </Tooltip>
                  </Stack>
                </Stack>
                <Stack direction={"row"} alignItems={"center"}>
                  <Stack direction={"row"} gap={0.5}>
                    <Tooltip
                      title={
                        getDuration() <= 20
                          ? "Duration should be greater than 20 sec"
                          : "Decrease end time by 10 seconds"
                      }
                      arrow
                      placement="top"
                    >
                      <span>
                        <Button
                          variant="outlined"
                          disabled={getDuration() <= 20 ? true : false}
                          sx={{ px: 0.5, minWidth: 0 }}
                          onClick={() => handleDurationChange(-10, false)}
                        >
                          <Remove sx={{ fontSize: "16px" }} />
                          10
                        </Button>
                      </span>
                    </Tooltip>
                    <Tooltip
                      title={
                        getDuration() >= 60
                          ? "Duration should be less than 1 min"
                          : "Increase end time by 10 seconds"
                      }
                      arrow
                      placement="top"
                    >
                      <span>
                        <Button
                          variant="outlined"
                          onClick={() => handleDurationChange(10, false)}
                          disabled={getDuration() >= 60 ? true : false}
                          sx={{ px: 0.5, minWidth: 0 }}
                        >
                          10
                          <Add sx={{ fontSize: "16px" }} />
                        </Button>
                      </span>
                    </Tooltip>
                  </Stack>
                  <Stack>
                    <Tooltip
                      disableFocusListener
                      title={
                        getDuration() >= 60
                          ? "Duration should be less than 1 min"
                          : "Increase end time by 1 second"
                      }
                      arrow
                      placement="right"
                    >
                      <span>
                        <IconButton
                          onClick={() => handleDurationChange(1, false)}
                          disabled={getDuration() >= 60 ? true : false}
                          sx={{
                            pb: 0,
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                          }}
                        >
                          <ArrowDropUpIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip
                      title={
                        getDuration() <= 20
                          ? "Duration should be greater than 20 sec"
                          : "Decrease end time by 1 second"
                      }
                      arrow
                      placement="right"
                    >
                      <span>
                        <IconButton
                          onClick={() => handleDurationChange(-1, false)}
                          disabled={getDuration() <= 20 ? true : false}
                          sx={{
                            pt: 0,
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                          }}
                        >
                          <ArrowDropDownIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Stack>
                </Stack>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 1,
                  mt: 1,

                  alignItems: "center",
                }}
              >
                <Select
                  size="small"
                  name="category"
                  value={currentClip?.category || "-1"}
                  sx={{ fontSize: "14px" }}
                  defaultValue={currentClip?.category || "-1"}
                  onChange={handleChangeClips}
                >
                  <MenuItem value="-1" sx={{ display: "none" }}>
                    Select Category{" "}
                  </MenuItem>
                  {CATEGORY?.map((el: any, index: number) => {
                    return (
                      <MenuItem key={index + 1} value={el.value}>
                        {el?.key}
                      </MenuItem>
                    );
                  })}
                </Select>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 0.5,
                    alignItems: "center",
                  }}
                >
                  <button
                    onClick={() => {
                      handleSubmitData(
                        currentClip,
                        currentClip?.video_clip_uuid
                      );
                    }}
                    style={{ fontSize: "14px", padding: "4px 8px" }}
                    className={configData.buttonCommonClass}
                  >
                    Save
                  </button>

                  <button
                    style={{
                      backgroundColor: "transparent",
                      fontSize: "14px",
                      padding: "3px",
                      border: "1px solid",
                      borderColor: configData.primary,
                      color: configData.primary,
                    }}
                    onClick={() => {
                      setCurrentClip(null);
                      setIsEdit(false);
                    }}
                    className={configData.buttonCommonClass}
                  >
                    Cancel
                  </button>
                </Box>
              </Box>
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    mt: 3,
                    mx: 2,
                    fontStyle: "normal",
                    textTransform: "none",
                  }}
                >
                  To edit your clip's duration and details, use the tools above.
                  Max clip length: 1 min
                </Typography>
              </Box>
            </Box>
          ) : (
            clipsData
              ?.sort((a: any, b: any) =>
                a?.start_time > b?.start_time ? 1 : -1
              )
              ?.filter((el: any) =>
                el?.name?.toLowerCase()?.includes(search?.plays?.toLowerCase())
              )
              .filter((el: any) => el.active)
              ?.map((el: any) => (
                <ClipsData
                  el={el}
                  setCurrentClip={setCurrentClip}
                  isDownloadStarted={isDownloadStarted}
                  setIsDownLoadStarted={setIsDownLoadStarted}
                  GetClipsDetails={() => GetClipsDetails()}
                  handleDelete={(video_clip_uuid: any) => {
                    handleDelete(video_clip_uuid);
                  }}
                  isLive={playerRef.current.isLive()}
                  setIsEdit={setIsEdit}
                  isEdit={isEdit}
                  handleSubmitData={(value: any, video_clip_uuid: any) =>
                    handleSubmitData(value, video_clip_uuid)
                  }
                  subscriptionDetails={subscriptionDetails}
                  videoUrl={
                    videoAway && gameDetails?.watch_url_away
                      ? gameDetails?.watch_url_away
                      : gameDetails?.watch_url
                  }
                  vod_url={
                    videoAway && gameDetails?.vod_url_away
                      ? gameDetails?.vod_url_away
                      : gameDetails?.vod_url
                  }
                  jumptoTime={() => jumpToTime(el?.start_time)}
                />
              ))
          )}
        </Box>
      </TabPanel>
      <TabPanel
        value={Object.keys(activeIcon)?.[0]}
        index={"highlight"}
        dir={theme.direction}
      >
        {activeIcon?.highlight &&
          (process.env.REACT_APP_FEATURE != "true" ? (
            <Box sx={{ p: 1 }}>
              <Box sx={{ py: 1 }}>
                <TextField
                  size="small"
                  placeholder="Filter"
                  onChange={(e: any) =>
                    setSearch({
                      ...search,
                      hightlight: e.target.value,
                    })
                  }
                  autoComplete="off"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#DADADA",
                      },
                    },
                    input: {
                      color: "white",
                      fontSize: "14px",
                      fontWeight: 600,
                    },
                  }}
                />
              </Box>

              {hightLighData ? (
                hightLighData
                  ?.sort((a: any, b: any) =>
                    a?.start_time > b?.start_time ? 1 : -1
                  )
                  ?.filter((el: any) =>
                    el?.name
                      ? el?.name
                          ?.toLowerCase()
                          ?.includes(search?.hightlight?.toLowerCase())
                      : el
                  )
                  ?.map((el: any) => (
                    <HighLight
                      el={el}
                      hide={true}
                      handleDelete={(video_clip_uuid: any) => {
                        handleDelete(video_clip_uuid);
                      }}
                      handleSubmitData={(value: any, video_clip_uuid: any) =>
                        handleSubmitData(value, video_clip_uuid)
                      }
                      jumptoTime={() => jumpToTime(el?.start_time)}
                    />
                  ))
              ) : (
                <Typography variant="body2" sx={{ color: "white" }}>
                  There are currently no highlights for this game.
                </Typography>
              )}
            </Box>
          ) : (
            <Box sx={{ p: 1 }}>
              <Box>
                <button
                  onClick={() => handleChangeIcon("stats")}
                  className={configData.buttonCommonClass}
                >
                  Go Back
                </button>
                <Box
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "5px",
                    mb: 1,
                  }}
                >
                  <ToggleButtonGroup
                    value={selectedTabAction}
                    exclusive
                    onChange={handleTabChangeAction}
                    aria-label="tabs"
                    sx={{
                      whiteSpace: "nowrap",
                      width: "100%",
                      ".MuiToggleButton-root": {
                        flex: 1,
                        fontSize: "10px",
                        color: "black",
                        textTransform: "none",
                        opacity: 1,
                      },
                    }}
                    size="small"
                  >
                    <ToggleButton
                      value={"GOAL"}
                      aria-label="Tab 1"
                      sx={{
                        "&.Mui-selected, &.Mui-selected:hover": {
                          color: "white",
                          backgroundColor: configData.secondary,
                        },
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      selected={selectedTabAction == "GOAL"}
                    >
                      GOALS
                    </ToggleButton>
                    <ToggleButton
                      value={"HIT"}
                      aria-label="Tab 1"
                      sx={{
                        "&.Mui-selected, &.Mui-selected:hover": {
                          color: "white",
                          backgroundColor: configData.secondary,
                        },
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      selected={selectedTabAction == "HIT"}
                    >
                      HITS
                    </ToggleButton>
                    <ToggleButton
                      value={"SAVE"}
                      aria-label="Tab 1"
                      sx={{
                        "&.Mui-selected, &.Mui-selected:hover": {
                          color: "white",
                          backgroundColor: configData.secondary,
                        },
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      selected={selectedTabAction == "SAVE"}
                    >
                      SAVES
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                <Box
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "5px",
                    mb: 1,
                  }}
                >
                  <ToggleButtonGroup
                    value={selectedTab}
                    exclusive
                    onChange={handleTabChange}
                    aria-label="tabs"
                    sx={{
                      whiteSpace: "nowrap",
                      width: "100%",
                      ".MuiToggleButton-root": {
                        flex: 1,
                        fontSize: "10px",
                        color: "black",
                        textTransform: "none",
                        opacity: 1,
                      },
                    }}
                    size="small"
                  >
                    <ToggleButton
                      value={"AWAY"}
                      aria-label="Tab 1"
                      sx={{
                        "&.Mui-selected, &.Mui-selected:hover": {
                          color: "white",
                          backgroundColor: configData.secondary,
                        },
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      selected={selectedTab == "AWAY"}
                    >
                      {gameDetails.away_team_short}
                    </ToggleButton>
                    <ToggleButton
                      sx={{
                        "&.Mui-selected, &.Mui-selected:hover": {
                          color: "white",
                          backgroundColor: configData.secondary,
                        },
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      value={"HOME"}
                      selected={selectedTab == "HOME"}
                      aria-label="Tab 2"
                    >
                      {gameDetails.home_team_short}
                    </ToggleButton>
                    <ToggleButton
                      sx={{
                        "&.Mui-selected, &.Mui-selected:hover": {
                          color: "white",
                          backgroundColor: configData.secondary,
                        },
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      value="0"
                      aria-label="Tab 3"
                      selected={selectedTab == "0"}
                    >
                      All
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>

                {videoList?.length > 0 ? (
                  <Box>
                    <Stack
                      sx={{
                        my: 1,
                        mx: "auto",
                        width: 1,
                        justifyContent: "center",
                      }}
                      spacing={2}
                    >
                      <ThemeProvider
                        theme={createTheme({
                          palette: {
                            mode: "dark",
                          },
                        })}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            bgcolor: "primary.main",
                            justifyContent: "space-between",
                          }}
                        >
                          <Button
                            variant="contained"
                            disabled={videoClipsData?.isFirstPage}
                            onClick={handlePrevious}
                          >
                            Previous
                          </Button>
                          <Button
                            variant="contained"
                            disabled={videoClipsData?.isLastPage}
                            onClick={handleNext}
                          >
                            Next
                          </Button>
                        </Box>
                      </ThemeProvider>
                    </Stack>
                    {isLoading ? (
                      <CircularProgress sx={{ color: "#fff" }} />
                    ) : (
                      videoList?.map((el: any, index: any) => (
                        <Box
                          onClick={() => {
                            playerRefLinius.current.seek(
                              findCurrentClipTime(el, index)
                            );
                          }}
                        >
                          <MatchCardv2
                            isHighLight={true}
                            gameDetails={gameDetails}
                            isDownloadAll={isDownloadAll}
                            setIsDownloadAll={setIsDownloadAll}
                            allDownloadVideo={allDownloadVideo}
                            subscriptionDetails={subscriptionDetails}
                            el={el}
                            active={activeClip === el?.game_event_id}
                            thubmnails={thubmnails[index]}
                          />
                        </Box>
                      ))
                    )}
                  </Box>
                ) : (
                  // <HighLight
                  //   el={el}
                  //   hide={true}
                  //   jumptoTime={() => jumpToTime("")}
                  // />

                  <Typography variant="body2" sx={{ color: "white" }}>
                    There are currently no highlights for this game.
                  </Typography>
                )}
              </Box>
            </Box>
          ))}
      </TabPanel>
      <TabPanel
        value={Object.keys(activeIcon)?.[0]}
        index={"chat"}
        dir={theme.direction}
      >
        <Box
          sx={{
            flexGrow: 1,
            py: 1,
            height: "100%",
          }}
        >
          <ChatComponent chatRoom={chatRoom} />
        </Box>
      </TabPanel>
      <TabPanel
        value={Object.keys(activeIcon)?.[0]}
        index={"watch"}
        dir={theme.direction}
      >
        <Box
          sx={{
            position: "relative",
            flexDirection: "column",
            justifyContent: "space-between",
            height: 1,
            flexGrow: 1,
          }}
        >
          <Login
            videoUrl={
              videoAway && gameDetails?.watch_url_away
                ? gameDetails?.watch_url_away
                : gameDetails?.watch_url
            }
          />
        </Box>
      </TabPanel>
      <TabPanel
        value={Object.keys(activeIcon)?.[0]}
        index={"action"}
        dir={theme.direction}
      >
        <Box
          sx={{
            position: "relative",
            flexDirection: "column",
            justifyContent: "space-between",
            height: 1,
            flexGrow: 1,
            bgcolor: "#fff",
          }}
        >
          <PollComponent />
        </Box>
      </TabPanel>
    </Box>
  );
};
export default VideoPlayerSideBar;
