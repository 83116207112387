import {
  Delete,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import {
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Table,
  TablePagination,
  IconButton,
  useTheme,
} from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import React, { useState } from "react";
import FirstPageIcon from "@mui/icons-material/FirstPage";

import LastPageIcon from "@mui/icons-material/LastPage";
import { LoadingButton } from "@mui/lab";
import PageRoutes, { APIRoutes, getUrl } from "../../../../utils/constants";
import moment from "moment";
import useConfig from "../../../../hooks/useConfig";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import HttpService from "../../../../hooks/Https-services";
import { toast } from "react-toastify";
import ImageDialogBox from "../../SliderSettings/ReorderTable/dialog";
interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const WhizzardTable = ({
  listOfWhizzard,
  GetWhizzard,
  pageData,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
}: {
  listOfWhizzard: any[];
  pageData: any;
  GetWhizzard: () => void;
  page: number;
  rowsPerPage: number;
  handleChangePage: any;
  handleChangeRowsPerPage: any;
}) => {
  const configData = useConfig();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const handleUnpublish = async (id: string) => {
    try {
      const res = await HttpService.post(
        APIRoutes.UnPublishWhizzard.replace(":id", id),
        {}
      );
      toast.success("Whizzard unpublished successfully");
      GetWhizzard();
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };
  const handlePublish = async (id: string) => {
    try {
      const res = await HttpService.post(
        APIRoutes.PublishWhizzard.replace(":id", id),
        {}
      );
      toast.success("Whizzard published successfully");
      GetWhizzard();
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };
  const handleRegenerateHighLight = async (id: string) => {
    try {
      setIsLoading(true);
      const res = await HttpService.post(
        APIRoutes.RegenerateWhizzard.replace(":id", id),
        {}
      );
      toast.success("HighLight regenerated successfully");
      setIsLoading(false);
      GetWhizzard();
    } catch (error: any) {
      toast.error(error.response.data.message);
      setIsLoading(false);
    }
  };
  const handelDeleteAd = async (id: string) => {
    try {
      const res = await HttpService.post(
        APIRoutes.DeleteWhizzard.replace(":id", id),
        {}
      );
      toast.success("Whizzard deleted successfully");
      GetWhizzard();
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Thumbnail</TableCell>
            <TableCell> Date</TableCell>
            <TableCell align="left">Title</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listOfWhizzard.map((row: any, index: any) => (
            <TableRow
              key={index + 1}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {" "}
              <TableCell align="left">
                <ImageDialogBox
                  src={
                    row?.IsAutomated && !row?.Thumbnail
                      ? getUrl(row?.HighlightUrl)
                      : row?.Thumbnail
                  }
                />
              </TableCell>
              <TableCell align="left">
                {" "}
                {row?.HighlightDate
                  ? moment(row?.HighlightDate).format("DD MMM YYYY hh:mm A")
                  : "Not Available"}
              </TableCell>
              <TableCell align="left">{row?.Title}</TableCell>
              <TableCell align="right">
                <Box
                  sx={{ display: "flex", gap: 1, justifyContent: "flex-end" }}
                >
                  {!!row?.IsAutomated &&
                    ["nahl", "dev", "ssm", "usah"].includes(
                      configData.name
                    ) && (
                      <LoadingButton
                        loading={isLoading}
                        onClick={() => handleRegenerateHighLight(row.ID)}
                        variant="contained"
                      >
                        <AutorenewIcon />
                      </LoadingButton>
                    )}
                  <button
                    onClick={() => {
                      if (row?.IsActive) {
                        handleUnpublish(row?.ID);
                      } else {
                        handlePublish(row?.ID);
                      }
                    }}
                    style={{
                      cursor: "pointer",
                      width: "200px",
                    }}
                    className={configData.buttonCommonClass}
                  >
                    {row?.IsActive ? "Move to Trash" : "Make Available"}
                  </button>{" "}
                  <button
                    onClick={() =>
                      navigate(PageRoutes.EditWhizzard.replace(":id", row?.ID))
                    }
                    style={{
                      cursor: "pointer",
                      width: "50px",
                    }}
                    className={configData.buttonCommonClass}
                  >
                    <EditIcon />
                  </button>
                  <button
                    onClick={() => handelDeleteAd(row?.ID)}
                    style={{
                      cursor: "pointer",
                      width: "50px",
                    }}
                    className={configData.buttonCommonClass}
                  >
                    <Delete />
                  </button>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        colSpan={3}
        rowsPerPageOptions={[5, 10, 25]}
        count={pageData.total_entries}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </TableContainer>
  );
};
export default WhizzardTable;
