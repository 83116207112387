import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AppsIcon from "@mui/icons-material/Apps";
import {
  AppRegistration,
  CardGiftcard,
  Event,
  Home,
  Login,
} from "@mui/icons-material";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import LiveTvIcon from "@mui/icons-material/LiveTv";

import useAxiosPrivate, {
  getCurrentAuth,
} from "../../../hooks/useAxiosPrivate";
import PageRoutes, { APIRoutes, UserRoles } from "../../../utils/constants";
import Header from "../Header";
import Footer from "../Footer";
import {
  faCircleQuestion,
  faCreditCard,
  faLock,
  faGrin,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useConfig from "../../../hooks/useConfig";
import axios from "../../../utils/axios";
import useCommonDetails from "../../Hooks/useCommonDetails";
import useCheckSubscibed from "../../../hooks/useCheckSubscibed";
import LoadingContext from "../../Contex/LoadingContex";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MainContainer({
  children,
}: {
  children: React.ReactElement;
}) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const userDetais = useCommonDetails();
  const configData = useConfig();
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const auth = getCurrentAuth();
  let location = useLocation();
  const [searchParams, setSearchPasarams] = useSearchParams();

  const game_id = searchParams.get("game_id");
  const handleNavigate = (game_id: any) => {
    navigate(PageRoutes.Watch.replace(":gameId", game_id));
  };
  return (
    <Box>
      <CssBaseline />
      <AppBar sx={{ backgroundColor: "#edf2f7" }} position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              ...(open
                ? { display: "none" }
                : { display: { xs: "none", md: "block" } }),
            }}
          >
            <MenuIcon sx={{ color: "black" }} />
          </IconButton>
          <Box sx={{ width: 1 }}>
            <Header />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{ display: { xs: "none", md: "block" } }}
        variant="permanent"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: 1,
          }}
        >
          <List>
            <ListItem
              onClick={() => {
                navigate(PageRoutes.HomePage);
                handleDrawerClose();
              }}
              disablePadding
              sx={{ display: "block" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Home />
                </ListItemIcon>
                <ListItemText
                  primary="HOME"
                  sx={{ opacity: open ? 1 : 0, fontStyle: "italic" }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              onClick={() => {
                navigate(PageRoutes.UpcomingEvent);
                handleDrawerClose();
              }}
              disablePadding
              sx={{ display: "block" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Event />
                </ListItemIcon>
                <ListItemText
                  primary="EVENTS"
                  sx={{ opacity: open ? 1 : 0, fontStyle: "italic" }}
                />
              </ListItemButton>
            </ListItem>
            {configData?.planpage && (
              <ListItem
                onClick={() => {
                  navigate(PageRoutes.Packages);
                  handleDrawerClose();
                }}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCreditCard}
                      style={{ color: "#696969", fontSize: "20px" }}
                    />
                  </ListItemIcon>

                  <ListItemText
                    primary={"PLANS"}
                    sx={{ opacity: open ? 1 : 0, fontStyle: "italic" }}
                  />
                </ListItemButton>
              </ListItem>
            )}

            {auth.AccessToken && process.env.REACT_APP_INSIDER == "true" && (
              <ListItem
                onClick={() => {
                  process.env.REACT_APP_FEATURE !== "true"
                    ? navigate({
                        pathname: PageRoutes.PersonalChannel.replace(
                          ":id",
                          "0"
                        ),
                        search: "?tab_id=1",
                      })
                    : userDetais?.isChannelCreated
                    ? navigate(PageRoutes.PersonalChannel.replace(":id", "0"))
                    : navigate(
                        PageRoutes.MyNAHLTV.replace(":id", "0").replace(
                          ":channel_id",
                          "0"
                        )
                      );
                  handleDrawerClose();
                }}
                disablePadding
                sx={{
                  display: "block",
                  bgcolor:
                    location.pathname.includes("my-channel") ||
                    location.pathname.includes("personal-channel")
                      ? configData?.primary
                      : "",
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color:
                        location.pathname.includes("my-channel") ||
                        location.pathname.includes("personal-channel")
                          ? configData?.tertiary
                          : "",
                    }}
                  >
                    {/* <FontAwesomeIcon
                    icon={faCreditCard}
                    style={{ color: "#696969", fontSize: "20px" }}
                  /> */}
                    <LiveTvIcon />
                  </ListItemIcon>

                  <ListItemText
                    primary={`My${configData.personalChannel}`}
                    sx={{
                      opacity: open ? 1 : 0,
                      fontStyle: "italic",
                      ".MuiListItemText-primary": {
                        color:
                          location.pathname.includes("my-channel") ||
                          location.pathname.includes("personal-channel")
                            ? `${configData?.tertiary}`
                            : "",
                      },
                    }}
                  />
                </ListItemButton>
              </ListItem>
            )}
            {auth.AccessToken && configData?.planpage && (
              <ListItem
                onClick={() => {
                  navigate(PageRoutes.GiftPage);
                  handleDrawerClose();
                }}
                disablePadding
                sx={{
                  display: "block",
                  bgcolor:
                    location.pathname == PageRoutes.GiftPage
                      ? configData?.primary
                      : "",
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color:
                        location.pathname == PageRoutes.GiftPage
                          ? configData?.tertiary
                          : "",
                    }}
                  >
                    {/* <FontAwesomeIcon
                    icon={faCreditCard}
                    style={{ color: "#696969", fontSize: "20px" }}
                  /> */}
                    <CardGiftcard />
                  </ListItemIcon>

                  <ListItemText
                    primary={"BUY A GIFT"}
                    sx={{
                      opacity: open ? 1 : 0,
                      fontStyle: "italic",
                      ".MuiListItemText-primary": {
                        color:
                          location.pathname == PageRoutes.GiftPage
                            ? `${configData?.tertiary}`
                            : "",
                      },
                    }}
                  />
                </ListItemButton>
              </ListItem>
            )}
            {auth.AccessToken &&
              ["nahl", "dev", "ssm", "usah"].includes(configData.name) && (
                <ListItem
                  onClick={() => {
                    navigate(PageRoutes.ShortContent);
                    handleDrawerClose();
                  }}
                  disablePadding
                  sx={{
                    display: "block",
                    bgcolor:
                      location.pathname == PageRoutes.ShortContent
                        ? configData?.primary
                        : "",
                  }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color:
                          location.pathname == PageRoutes.ShortContent
                            ? configData?.tertiary
                            : "",
                      }}
                    >
                      <AppsIcon />
                    </ListItemIcon>

                    <ListItemText
                      primary={"HIGHLIGHTS"}
                      sx={{
                        opacity: open ? 1 : 0,
                        fontStyle: "italic",
                        ".MuiListItemText-primary": {
                          color:
                            location.pathname == PageRoutes.ShortContent
                              ? `${configData?.tertiary}`
                              : "",
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              )}
            {auth.AccessToken &&
              (userDetais?.UserRoleID == UserRoles.HTP_ADMIN ||
                userDetais?.UserRoleID == UserRoles.LEAGUE_ADMIN ||
                userDetais?.UserRoleID == UserRoles.CUSTOMER_ADMIN) && (
                <ListItem
                  onClick={() => {
                    navigate(
                      userDetais?.UserRoleID === UserRoles.CUSTOMER_ADMIN
                        ? PageRoutes.SliderSetting
                        : PageRoutes.Admin
                    );
                    handleDrawerClose();
                  }}
                  disablePadding
                  sx={{
                    display: "block",
                    bgcolor: location.pathname.includes("admin")
                      ? configData?.primary
                      : "",
                  }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faLock}
                        style={{
                          color: location.pathname.includes("admin")
                            ? configData?.tertiary
                            : "",
                          fontSize: "20px",
                        }}
                      />
                    </ListItemIcon>

                    <ListItemText
                      primary={"ADMIN"}
                      sx={{
                        opacity: open ? 1 : 0,
                        fontStyle: "italic",
                        ".MuiListItemText-primary": {
                          color: location.pathname.includes("admin")
                            ? `${configData?.tertiary}`
                            : "",
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              )}
            {!auth.AccessToken && (
              <ListItem
                onClick={() => {
                  navigate(PageRoutes.Register);
                  handleDrawerClose();
                }}
                disablePadding
                sx={{ display: { xs: "block", md: "none" } }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,

                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <AppRegistration />
                  </ListItemIcon>
                  <ListItemText
                    primary={"REGISTER"}
                    sx={{ opacity: open ? 1 : 0, fontStyle: "italic" }}
                  />
                </ListItemButton>
              </ListItem>
            )}
            {!auth?.AccessToken && (
              <ListItem
                onClick={() => {
                  navigate(PageRoutes.Login);
                  handleDrawerClose();
                }}
                disablePadding
                sx={{ display: { xs: "block", md: "none" } }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Login />
                  </ListItemIcon>
                  <ListItemText
                    primary={"LOGIN"}
                    sx={{ opacity: open ? 1 : 0, fontStyle: "italic" }}
                  />
                </ListItemButton>
              </ListItem>
            )}
          </List>
          <Divider />
          <ListItem
            onClick={() => {
              navigate(PageRoutes.ContactUS);
            }}
            disablePadding
            sx={{ display: "block" }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <FontAwesomeIcon icon={faCircleQuestion} />
              </ListItemIcon>
              <ListItemText
                primary="SUPPORT"
                sx={{ opacity: open ? 1 : 0, fontStyle: "italic" }}
              />
            </ListItemButton>
          </ListItem>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, ml: { xs: "none", md: 6 } }}>
        <DrawerHeader />
        <Box sx={{ minHeight: "100vh" }}>{children}</Box>
        <Footer />
      </Box>
    </Box>
  );
}
