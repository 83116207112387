import React from "react";
import { useState, useRef, useEffect } from "react";
import { WHIZZARD_TYPE } from "../../../utils/constants";
import { Box } from "@mui/material";
import ChannelHighLights from "../ChannelHighLights";

export const GoalAndCondenced = ({
  id,
  channel,
  hasPermition,
  type,
}: {
  id: number;
  channel: any;
  hasPermition: boolean;
  type: number;
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  console.log(isVisible ? "Visible" : "Not Visible");

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        console.log(entry);
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        threshold: 0.1, // Requires 75% visibility before loading
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);
  const typeName = WHIZZARD_TYPE[type as keyof typeof WHIZZARD_TYPE];
  return (
    <div ref={ref} style={{ height: "390px" }}>
      {!!isVisible && (
        <Box key={channel.id}>
          <ChannelHighLights
            hasPermition={hasPermition}
            channel_id={channel.channel_id}
            channel_name={channel.channel_name}
            type_name={typeName}
            type={type}
          />
        </Box>
      )}
    </div>
  );
};
