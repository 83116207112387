import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import PageRoutes, { APIRoutes } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import LoadingContext from "../../../Common/Contex/LoadingContex";
import HttpService from "../../../hooks/Https-services";
import useConfig from "../../../hooks/useConfig";
import PlayerAlertVideo from "./PlayerAlertVideo";
import AdsVideos from "./AdsVideos";
import AdsVideosList from "./AdsVideos";

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ width: "100%" }}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const MediaList = () => {
  const [listOfMedia, setListOfMedia] = useState<any>([]);
  const [isActive, setIsActive] = useState<any>(true);
  const [listOfInactiveAds, setListOfInactiveAds] = useState<any>([]);
  const { isRefresh, setIsRefresh }: any = useContext(LoadingContext);

  const [isSkeleton, setIsSkeleton] = useState(true);
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setIsActive(true);
  };
  const navigate = useNavigate();
  const configData = useConfig();

  const handleUnpublish = async (id: any) => {
    try {
      const res = await HttpService.post(
        APIRoutes.UnPublishNotification.replace(":id", id),
        {}
      );
    } catch (error) {}
  };

  const GetMediaList = useCallback(async () => {
    try {
      const res = await HttpService.get(
        `${APIRoutes.getMediaList}?isActive=${isActive}&type=${value + 1}`
      );
      setListOfMedia(res?.data?.data);
    } catch (error) {}
  }, [value, isActive, isRefresh]);
  useEffect(() => {
    GetMediaList();
  }, [GetMediaList]);

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", py: 2 }}>
        <Typography variant="h3">Library</Typography>
        <Box>
          <button
            onClick={() => navigate(PageRoutes.MediaCreate.replace(":id", "0"))}
            style={{
              cursor: "pointer",
            }}
            className={configData.buttonCommonClass}
          >
            Add Media
          </button>{" "}
        </Box>
      </Box>
      <Box>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Images" {...a11yProps(0)} />
          <Tab label="Videos" {...a11yProps(1)} />
          <Tab label="Poll Images" {...a11yProps(2)} />
          <Tab label="Thumbnails" {...a11yProps(3)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <PlayerAlertVideo
            listOfMedia={listOfMedia}
            setIsActive={setIsActive}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AdsVideosList listOfMedia={listOfMedia} setIsActive={setIsActive} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <PlayerAlertVideo
            listOfMedia={listOfMedia}
            setIsActive={setIsActive}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <PlayerAlertVideo
            listOfMedia={listOfMedia}
            setIsActive={setIsActive}
          />
        </TabPanel>
      </Box>
    </Box>
  );
};
export default MediaList;
