import React, { useEffect, useState } from "react";
// import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Login from "./Pages/Login";
import PageRoutes, { UserRoles } from "./utils/constants";

import PrivateRoutes from "./Common/RouteAccessor/PrivateRoute";
import PublicRoute from "./Common/RouteAccessor/PublicRoute";
import HomePage from "./Pages/HomePage";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import UpcomingEvent from "./Pages/UpcomingEvent";
import OnDemand from "./Pages/OnDemand";
import Register from "./Pages/Register";
import VerifyEmail from "./Pages/EmailVefiy";
import ForgotPassword from "./Pages/ForgotPassword";
import EditProfile from "./Pages/MyAccount/EditProfile";
import TermAndCondition from "./Pages/TermAndCondition";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Alert from "./Pages/AlertPage";
import NoPageFound from "./Pages/NoPageFound";
import Payment from "./Pages/Payment/Payment";
import ReactGA from "react-ga4";
import ProfileSetting from "./Pages/ProfileSetting";
import PaymentInformation from "./Pages/MyAccount/PaymentInfomation";
import ConfigData from "./Config/config.json";

import WatchGame from "./Pages/Watch/watch";
import { Helmet } from "react-helmet";

import PassesMain from "./Pages/Passes/Passes";
import Admin from "./Pages/Admin";
import AdminRoutes from "./Common/RouteAccessor/AdminRoutes";
import TinyEditor from "./Pages/Admin/AlertDesign/TinyEditor";
import CreateSliderForm from "./Pages/Admin/SliderSettings/CreateSliderForm";
import GiftPage from "./Pages/GiftPage";
import MyNahlTv from "./Pages/MyNAHLTV/MyNahlTv";
import PersonalChannelSubscribe from "./Pages/PersonalChannel/PersonalChannelSubscribe";
import UserView from "./Pages/Admin/UsersList/UserView";
import { LoadingContextProvider } from "./Common/Contex/LoadingContex";
import { ThemeProviderReact } from "./Common/Contex/ThemeContex";
import CreatePlayerError from "./Pages/Admin/PlayerAlertList/PlayerAlertListTable/CreatePlayerError";
import CreateAds from "./Pages/Admin/Ads/CreateAds";
import SupportPage from "./Pages/SupportPage";
import AddMedia from "./Pages/Admin/Media/AddMedia";
import CreatePoll from "./Pages/Admin/Poll/PollListTable/CreatePoll";
import EditWhizzard from "./Pages/Admin/Whizzard/CreateWhizzard";
import Highlights from "./Pages/HighlightPlayer";
import ShortContent from "./Pages/ShortContent";
import ShortView from "./Pages/ShortContent/ShortView";
import FilterShortFormContent from "./Pages/ShortContent/FilterShortFormContent";
declare global {
  interface Window {
    dataLayer: any[];
  }
}

function App() {
  const [faviconUrl, setFaviconUrl] = useState<string>("");
  const [logoUrl, setLogoUrl] = useState<string>("");
  const [manifestData, setMenifest] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [config, setConfig] = useState<any>({});

  const handleConfig = () => {
    const data: any = ConfigData;
    const websiteName: any = process.env.REACT_APP_ENV;
    setFaviconUrl(data[websiteName]?.favicon);
    setConfig(data[websiteName]);
    setMenifest(`/${process.env.REACT_APP_ENV}manifest.json`);
    setTitle(data[websiteName]?.title);
    setLogoUrl(data[websiteName]?.logo);
    const googleAnalyticsId = data[websiteName]?.gTag;
    ReactGA.initialize(googleAnalyticsId);
  };
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/service-worker.js")
        .then((registration) => {
          console.log(
            "Service Worker registered with scope:",
            registration.scope
          );
        })
        .catch((error) => {
          console.error("Service Worker registration failed:", error);
        });
    }
  }, []);
  useEffect(() => {
    handleConfig();
    return () => {
      const existingFaviconLink = document.querySelector('link[rel="icon"]');
      if (existingFaviconLink) {
        document.head.removeChild(existingFaviconLink);
      }
    };
  }, []);

  return (
    <Router>
      <ThemeProviderReact>
        <LoadingContextProvider>
          <Helmet>
            <link rel="icon" type="image/x-icon" href={faviconUrl} />
            <link rel="manifest" href={manifestData} />
            <meta name="description" content={title} />
            <title>{title}</title>
            <meta name="application-name" content={title} />
            <meta name="theme-color" content="#ffffff" />
            <link rel="apple-touch-icon" href={logoUrl} />
            <link rel="shortcut icon" href={logoUrl} />
          </Helmet>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            limit={2}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            icon={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover={false}
            theme="light"
          />
          <Routes>
            <Route
              path={PageRoutes.Login}
              element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              }
            />
            <Route
              path={PageRoutes.Register}
              element={
                <PublicRoute>
                  <Register />
                </PublicRoute>
              }
            />
            <Route
              path={PageRoutes.PasswordReset}
              element={
                <PublicRoute>
                  <ForgotPassword />
                </PublicRoute>
              }
            />
            <Route
              path={PageRoutes.VerifyEmail}
              element={
                <PublicRoute>
                  <VerifyEmail />
                </PublicRoute>
              }
            />
            <Route
              path={PageRoutes.HomePage}
              element={
                <PublicRoute>
                  <HomePage />
                </PublicRoute>
              }
            />
            {config?.planpage && (
              <Route
                path={PageRoutes.Packages}
                element={
                  <PublicRoute>
                    <PassesMain isHomePage={false} />
                  </PublicRoute>
                }
              />
            )}
            <Route
              path={PageRoutes.TermAndConditions}
              element={
                <PublicRoute>
                  <TermAndCondition />
                </PublicRoute>
              }
            />
            <Route
              path={PageRoutes.PrivacyPolicy}
              element={
                <PublicRoute>
                  <PrivacyPolicy />
                </PublicRoute>
              }
            />
            <Route
              path={PageRoutes.ContactUS}
              element={
                <PublicRoute>
                  <SupportPage />
                </PublicRoute>
              }
            />
            <Route
              path={PageRoutes.Setting}
              element={
                <PrivateRoutes>
                  <ProfileSetting />
                </PrivateRoutes>
              }
            />
            <Route
              path={PageRoutes.Admin}
              element={
                <PrivateRoutes>
                  <AdminRoutes
                    allowedUser={[UserRoles.HTP_ADMIN, UserRoles.LEAGUE_ADMIN]}
                  >
                    <Admin />
                  </AdminRoutes>
                </PrivateRoutes>
              }
            />
            <Route
              path={PageRoutes.SliderSetting}
              element={
                <PrivateRoutes>
                  <AdminRoutes
                    allowedUser={[
                      UserRoles.HTP_ADMIN,
                      UserRoles.LEAGUE_ADMIN,
                      UserRoles.CUSTOMER_ADMIN,
                    ]}
                  >
                    <Admin />
                  </AdminRoutes>
                </PrivateRoutes>
              }
            />
            <Route
              path={PageRoutes.EditAds}
              element={
                <PrivateRoutes>
                  <AdminRoutes
                    allowedUser={[UserRoles.HTP_ADMIN, UserRoles.LEAGUE_ADMIN]}
                  >
                    <CreateAds />
                  </AdminRoutes>
                </PrivateRoutes>
              }
            />
            <Route
              path={PageRoutes.Users}
              element={
                <PrivateRoutes>
                  <AdminRoutes allowedUser={[UserRoles.HTP_ADMIN]}>
                    <Admin />
                  </AdminRoutes>
                </PrivateRoutes>
              }
            />
            <Route
              path={PageRoutes.PlayerAlerts}
              element={
                <PrivateRoutes>
                  <AdminRoutes
                    allowedUser={[UserRoles.HTP_ADMIN, UserRoles.LEAGUE_ADMIN]}
                  >
                    <Admin />
                  </AdminRoutes>
                </PrivateRoutes>
              }
            />
            <Route
              path={PageRoutes.Media}
              element={
                <PrivateRoutes>
                  <AdminRoutes
                    allowedUser={[UserRoles.HTP_ADMIN, UserRoles.LEAGUE_ADMIN]}
                  >
                    <Admin />
                  </AdminRoutes>
                </PrivateRoutes>
              }
            />
            <Route
              path={PageRoutes.MediaCreate}
              element={
                <PrivateRoutes>
                  <AdminRoutes
                    allowedUser={[UserRoles.HTP_ADMIN, UserRoles.LEAGUE_ADMIN]}
                  >
                    <AddMedia />
                  </AdminRoutes>
                </PrivateRoutes>
              }
            />
            <Route
              path={PageRoutes.Ads}
              element={
                <PrivateRoutes>
                  <AdminRoutes
                    allowedUser={[UserRoles.HTP_ADMIN, UserRoles.LEAGUE_ADMIN]}
                  >
                    <Admin />
                  </AdminRoutes>
                </PrivateRoutes>
              }
            />
            <Route
              path={PageRoutes.Polls}
              element={
                <PrivateRoutes>
                  <AdminRoutes
                    allowedUser={[UserRoles.HTP_ADMIN, UserRoles.LEAGUE_ADMIN]}
                  >
                    <Admin />
                  </AdminRoutes>
                </PrivateRoutes>
              }
            />
            <Route
              path={PageRoutes.Reports}
              element={
                <PrivateRoutes>
                  <AdminRoutes
                    allowedUser={[UserRoles.HTP_ADMIN, UserRoles.LEAGUE_ADMIN]}
                  >
                    <Admin />
                  </AdminRoutes>
                </PrivateRoutes>
              }
            />
            {process.env.REACT_APP_INSIDER == "true" && (
              <Route
                path={PageRoutes.Whizzard}
                element={
                  <PrivateRoutes>
                    <AdminRoutes
                      allowedUser={[
                        UserRoles.HTP_ADMIN,
                        UserRoles.LEAGUE_ADMIN,
                      ]}
                    >
                      <Admin />
                    </AdminRoutes>
                  </PrivateRoutes>
                }
              />
            )}

            <Route
              path={PageRoutes.UserView}
              element={
                <PrivateRoutes>
                  <AdminRoutes allowedUser={[UserRoles.HTP_ADMIN]}>
                    <UserView />
                  </AdminRoutes>
                </PrivateRoutes>
              }
            />
            {process.env.REACT_APP_INSIDER == "true" && (
              <Route
                path={PageRoutes.EditWhizzard}
                element={
                  <PrivateRoutes>
                    <AdminRoutes
                      allowedUser={[
                        UserRoles.HTP_ADMIN,
                        UserRoles.LEAGUE_ADMIN,
                      ]}
                    >
                      <EditWhizzard />
                    </AdminRoutes>
                  </PrivateRoutes>
                }
              />
            )}

            <Route
              path={PageRoutes.EditAnnouncement}
              element={
                <PrivateRoutes>
                  <AdminRoutes
                    allowedUser={[UserRoles.HTP_ADMIN, UserRoles.LEAGUE_ADMIN]}
                  >
                    <TinyEditor />
                  </AdminRoutes>
                </PrivateRoutes>
              }
            />

            <Route
              path={PageRoutes.EditSliderSetting}
              element={
                <PrivateRoutes>
                  <AdminRoutes
                    allowedUser={[
                      UserRoles.HTP_ADMIN,
                      UserRoles.LEAGUE_ADMIN,
                      UserRoles.CUSTOMER_ADMIN,
                    ]}
                  >
                    <CreateSliderForm />
                  </AdminRoutes>
                </PrivateRoutes>
              }
            />
            <Route
              path={PageRoutes.EditPlayerAlerts}
              element={
                <PrivateRoutes>
                  <AdminRoutes
                    allowedUser={[UserRoles.HTP_ADMIN, UserRoles.LEAGUE_ADMIN]}
                  >
                    <CreatePlayerError />
                  </AdminRoutes>
                </PrivateRoutes>
              }
            />
            <Route
              path={PageRoutes.EditPolls}
              element={
                <PrivateRoutes>
                  <AdminRoutes
                    allowedUser={[UserRoles.HTP_ADMIN, UserRoles.LEAGUE_ADMIN]}
                  >
                    <CreatePoll />
                  </AdminRoutes>
                </PrivateRoutes>
              }
            />
            <Route
              path={PageRoutes.Alert}
              element={
                <PrivateRoutes>
                  <Alert />
                </PrivateRoutes>
              }
            />

            <Route
              path={PageRoutes.Any}
              element={
                <PublicRoute>
                  <NoPageFound />
                </PublicRoute>
              }
            />
            {config?.planpage && (
              <Route
                path={PageRoutes.AccountPage}
                element={
                  <PrivateRoutes>
                    <PaymentInformation />
                  </PrivateRoutes>
                }
              />
            )}
            <Route
              path={PageRoutes.EditProfile}
              element={
                <PrivateRoutes>
                  <EditProfile />
                </PrivateRoutes>
              }
            />
            <Route
              path={PageRoutes.UpcomingEvent}
              element={
                <PublicRoute>
                  <UpcomingEvent />
                </PublicRoute>
              }
            />
            <Route
              path={PageRoutes.OnDemand}
              element={
                <PublicRoute>
                  <OnDemand />
                </PublicRoute>
              }
            />
            {process.env.REACT_APP_INSIDER == "true" && (
              <Route
                path={PageRoutes.MyNAHLTV}
                element={
                  <PrivateRoutes>
                    <MyNahlTv />
                  </PrivateRoutes>
                }
              />
            )}
            {process.env.REACT_APP_INSIDER == "true" && (
              <Route
                path={PageRoutes.PersonalChannel}
                element={
                  <PrivateRoutes>
                    <PersonalChannelSubscribe />
                  </PrivateRoutes>
                }
              />
            )}
            {process.env.REACT_APP_INSIDER == "true" && (
              <Route
                path={PageRoutes.HighLightPlayer}
                element={
                  <PrivateRoutes>
                    <Highlights />
                  </PrivateRoutes>
                }
              />
            )}
            <Route
              path={PageRoutes.paymentPage}
              element={
                <PrivateRoutes>
                  <Payment />
                </PrivateRoutes>
              }
            />
            {["nahl", "dev", "ssm", "usah"].includes(config.name) && (
              <Route
                path={PageRoutes.ShortContent}
                element={
                  <PrivateRoutes>
                    <ShortContent />
                  </PrivateRoutes>
                }
              />
            )}
            {["nahl", "dev", "ssm", "usah"].includes(config.name) && (
              <Route
                path={PageRoutes.FilterShortContent}
                element={
                  <PrivateRoutes>
                    <FilterShortFormContent />
                  </PrivateRoutes>
                }
              />
            )}
            {["nahl", "dev", "ssm", "usah"].includes(config.name) && (
              <Route
                path={PageRoutes.ShortView}
                element={
                  <PrivateRoutes>
                    <ShortView />
                  </PrivateRoutes>
                }
              />
            )}
            {config?.planpage && (
              <Route
                path={PageRoutes.GiftPage}
                element={
                  <PrivateRoutes>
                    <GiftPage />
                  </PrivateRoutes>
                }
              />
            )}
            <Route
              path={PageRoutes.Watch}
              element={
                <PrivateRoutes>
                  <WatchGame />
                </PrivateRoutes>
              }
            />
          </Routes>
        </LoadingContextProvider>
      </ThemeProviderReact>
    </Router>
  );
}

export default App;
