import {
  Box,
  Button,
  Collapse,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useRef, useState } from "react";

// import GoogleLogin from "react-google-login";

// import FacebookLogin from "react-facebook-login";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import { commonDetailSlice } from "../../store/reducers/commonReducer";
import { useDispatch } from "react-redux";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import axios from "../../utils/axios";
import PageRoutes, { APIRoutes } from "../../utils/constants";
import { setAuth } from "../../hooks/useRefreshToken";
import { toast } from "react-toastify";
import { VisibilityOff, Visibility, AccountCircle } from "@mui/icons-material";
import React from "react";
import useConfig from "../../hooks/useConfig";
import HttpService from "../../hooks/Https-services";

const ForgotPassword: FC = () => {
  const [flagLogin, setFlagLogin] = useState(true);
  const [serachParams, setSearchParams] = useSearchParams({ q: "true" });
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const configData = useConfig();
  const formikRef = useRef<any>(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const [openColleps, setopenColleps] = useState(true);
  const isToken = serachParams.get("token") ? true : false;
  const content = serachParams.get("content");
  const [isLoading, setIsLoading] = useState(false);
  async function getEntries(data: any) {
    dispatch(commonDetailSlice.actions.removecommonDetails());
    dispatch(commonDetailSlice.actions.setcommonDetails(data));
  }

  const [showPassword, setShowPassword] = useState({
    password: false,
    confirm_password: false,
  } as any);

  const handleClickShowPassword = (flag: any) => {
    const item = { ...showPassword };
    item[flag] = !showPassword[flag];

    setShowPassword(item);
  };
  const intialValue: {
    [key: string]: string;
  } = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
  };
  const validate = (values: any) => {
    const errors: any = {};
    const flag = serachParams.get("q");
    const emailRegx = new RegExp(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    const passwordRegx = new RegExp(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
    );
    if (isToken) {
      if (!values.password) {
        errors.password = "Please fill the required fields";
      } else if (!passwordRegx.test(values.password)) {
        errors.password =
          "The password must contain at least one lowercase letter, one uppercase letter, one digit, one symbol, and be a minimum of 8 characters long.";
      }

      if (!values.confirm_password) {
        errors.confirm_password = "Please fill the required fields";
      }
    } else {
      if (!values.email) {
        errors.email = "Please fill the required fields";
      } else if (!emailRegx.test(values.email)) {
        errors.email = "Please enter valid email id";
      }
    }

    return errors;
  };

  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: intialValue,
    validate,
    validateOnChange: false,
    onSubmit: async (values) => {
      if (confirmPasswordError) {
        return;
      }
      setIsLoading(true);
      if (isToken) {
        try {
          const password = values.password;
          const token = serachParams.get("token");
          const dataBody: any = {
            password,
            token: token,
          };
          const res: any = await HttpService.post(
            APIRoutes.ResetPassword,
            dataBody
          );
          if (res.data) {
            toast.success("Password Changed Successfully");
            navigate(PageRoutes.Login);
            setIsLoading(false);
          }
        } catch (error: any) {
          toast.error(error.response.data.message);
          setIsLoading(false);
          formik.resetForm();
        }
      } else {
        try {
          const email = values.email;
          const dataBody: any = {
            email,
          };
          const res: any = await HttpService.post(
            APIRoutes.ForgotPassword,
            dataBody
          );
          if (res.data) {
            toast.success(
              "We have sent you an email to reset your password.  Please note that the email may take up to 10 minutes to arrive.  Please do not initiate multiple password resets."
            );
            setIsLoading(false);
          }
        } catch (error: any) {
          toast.error(error.response.data.message);
          formik.resetForm();
          setIsLoading(false);
        }
      }
    },
  });

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <section className="main-content vh-100">
      <div className="container h-custom">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-md-7">
            {content && (
              <Collapse in={openColleps}>
                <div
                  style={{
                    maxWidth: "1110px",
                    margin: "0px auto",
                    padding: "10px",
                  }}
                >
                  <div>
                    <div
                      role="alert"
                      className="alert alert-secondary alert-dismissible fade show"
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: atob(content),
                        }}
                      ></div>
                      <button
                        onClick={() => setopenColleps(false)}
                        type="button"
                        data-dismiss="alert"
                        aria-label="Close"
                        className="close"
                      >
                        <span>×</span>
                      </button>
                    </div>
                  </div>
                </div>
              </Collapse>
            )}

            <div className="card">
              <Typography
                sx={{ fontStyle: "normal", m: "auto", width: 1, py: 2 }}
                variant="h1"
                className="card-header"
              >
                {isToken ? "Reset Password" : "Reset Password"}{" "}
              </Typography>
              <div className="card-body text-center">
                <form onSubmit={formik.handleSubmit}>
                  {isToken ? (
                    <div className="row justify-content-center mb-3">
                      {" "}
                      <div
                        data-v-202744ad=""
                        className="row justify-content-center"
                      >
                        <div
                          data-v-ee71dd48=""
                          data-v-202744ad=""
                          className="form form-group col-md-6"
                        >
                          <OutlinedInput
                            size="small"
                            data-v-ee71dd48=""
                            type={showPassword.password ? "text" : "password"}
                            name="password"
                            id="password"
                            value={formik.values.password}
                            onChange={(e) => {
                              formik.handleChange(e);
                              formik.errors.password = "";
                            }}
                            error={formik.errors.password ? true : false}
                            placeholder="Password"
                            sx={{ py: 0, pl: 0 }}
                            autoComplete="new-password"
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    handleClickShowPassword("password")
                                  }
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword.password ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            className="form-control"
                          />
                          <FormHelperText sx={{ color: "#d50000", px: 2 }}>
                            {" "}
                            {formik.errors.password}
                          </FormHelperText>
                        </div>
                      </div>
                      <div
                        data-v-202744ad=""
                        className="row justify-content-center"
                      >
                        <div
                          data-v-ee71dd48=""
                          data-v-202744ad=""
                          className="form form-group col-md-6"
                        >
                          <TextField
                            size="small"
                            data-v-ee71dd48=""
                            type={
                              showPassword.confirm_password
                                ? "text"
                                : "password"
                            }
                            name="confirm_password"
                            id="confirm_password"
                            onChange={(e) => {
                              formik.handleChange(e);
                              formik.errors.confirm_password = "";
                              setConfirmPasswordError("");
                            }}
                            onBlur={(e) => {
                              if (formik.values.password) {
                                if (formik.values.password != e.target.value) {
                                  setConfirmPasswordError(
                                    "Confirm password do not match with entered password"
                                  );
                                } else {
                                  setConfirmPasswordError("");
                                }
                              }
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() =>
                                        handleClickShowPassword(
                                          "confirm_password"
                                        )
                                      }
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showPassword.confirm_password ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                </InputAdornment>
                              ),
                            }}
                            error={
                              formik.errors.confirm_password ||
                              confirmPasswordError
                                ? true
                                : false
                            }
                            helperText={
                              formik.errors.confirm_password ||
                              confirmPasswordError
                            }
                            value={formik.values.confirm_password}
                            placeholder="Confirm Password"
                            autoComplete="current-password"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="row justify-content-center mb-3">
                      <div className="form form-group col-md-6">
                        <TextField
                          size="small"
                          data-v-ee71dd48=""
                          type="text"
                          autoComplete="username"
                          placeholder="Email"
                          name="email"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircle />
                              </InputAdornment>
                            ),
                          }}
                          error={formik.errors.email ? true : false}
                          helperText={formik.errors.email}
                          id="email"
                          value={formik.values.email}
                          onChange={(e) => {
                            formik.handleChange(e);
                            formik.errors.email = "";
                          }}
                          className="form-control"
                        />
                      </div>
                    </div>
                  )}

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Box>
                      <button
                        disabled={isLoading}
                        className={configData.buttonCommonClass}
                        type="submit"
                      >
                        Reset password
                      </button>
                    </Box>
                    <Box>
                      <Button
                        onClick={() => navigate(PageRoutes.Login)}
                        variant="text"
                        sx={{ textDecoration: "underline" }}
                      >
                        Back to login page
                      </Button>
                    </Box>
                  </Box>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ForgotPassword;
