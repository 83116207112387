import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import React, { useCallback, useContext, useEffect, useState } from "react";
import useConfig from "../../../hooks/useConfig";
import LoadingContext from "../../../Common/Contex/LoadingContex";
import HttpService from "../../../hooks/Https-services";
import { APIRoutes } from "../../../utils/constants";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import EllipsisContent from "../../../Common/Component/EllipsisContent";

const PollComponent = () => {
  const [view, setView] = React.useState<any>(null);
  const [isSkeleton, setIsSkeleton] = useState(true);
  const { pollsDetails, openColleps }: any = useContext(LoadingContext);
  const configData = useConfig();
  const [loading, setLoading] = useState(false);
  const [vote, setVote] = useState(null);
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newFormats: any
  ) => {
    setView(newFormats);
    if (pollsDetails?.PollType !== "multi_select") {
      if (typeof newFormats === "string" && newFormats !== null) {
        handleCastVote(newFormats);
      } else if (typeof newFormats === "object" && newFormats.length) {
        const findID = newFormats.filter((el: any) => el !== null && el);
        handleCastVote(findID);
      }
    }
  };
  const percentage = (el: any) => {
    return ((el.vote * 100) / pollsDetails.TotalVotes).toFixed(2);
  };
  const CheckIsUserGiveVote = useCallback(async () => {
    try {
      if (pollsDetails?.PollID) {
        const res = await HttpService.get(
          APIRoutes.CheckIsVoteGiven.replace(":id", pollsDetails?.PollID)
        );
        setVote(res?.data?.data);
      }
      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  }, [pollsDetails?.PollID]);
  const handleCastVote = async (voteGiven: any) => {
    setLoading(true);
    try {
      const res = await HttpService.post(
        APIRoutes.CastVote.replace(":id", pollsDetails?.PollID),
        {
          optionIds:
            pollsDetails?.PollType !== "multi_select" ? [voteGiven] : voteGiven,
        }
      );
      await CheckIsUserGiveVote();
      setLoading(false);
    } catch (error: any) {
      toast.error(error.response.data.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    CheckIsUserGiveVote();
  }, [CheckIsUserGiveVote]);
  if (isSkeleton) {
    return <CircularProgress />;
  }
  return (
    <Box
      sx={{
        p: 2,
        textAlign: "left",
        zIndex: 1,
        height: 1,
        position: "relative",
      }}
    >
      <Box
        sx={{
          content: '""', // Equivalent to `content: ''` in CSS
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: 0.1,
          backgroundColor: configData.primary, // Apply background with 50% opacity
          zIndex: -1,
        }}
      ></Box>
      {!!pollsDetails?.Question ? (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Avatar
                alt="Remy Sharp"
                src={pollsDetails?.PollBackgroundImage}
                sx={{
                  maxWidth: { xs: 1, sm: "300px" },
                  objectFit: "contain",
                  borderRadius: 0,
                  width: 1,
                  maxHeight: "200px",
                  height: 1,
                }}
              />
            </Box>

            <Box sx={{ bgcolor: configData.primary, p: 0.5 }}>
              <Typography
                sx={{ maxWidth: 0.7, zIndex: 2, color: configData.tertiary }}
                variant="subtitle1"
              >
                {pollsDetails?.Title}
              </Typography>
            </Box>
          </Box>

          <Typography
            sx={{ maxWidth: 0.7, zIndex: 2, mt: 2 }}
            variant="subtitle1"
          >
            {pollsDetails?.Question}
          </Typography>
          <ToggleButtonGroup
            orientation="vertical"
            value={view}
            fullWidth
            sx={{
              textAlign: "left",
              gap: 1,
            }}
            exclusive={pollsDetails?.PollType !== "multi_select" ? true : false}
            aria-label="text formatting"
            onChange={handleChange}
          >
            {pollsDetails?.Options?.map((el: any) => {
              return (
                <ToggleButton
                  fullWidth
                  sx={{
                    justifyContent: "start",
                    textAlign: "start",
                    width: 1,
                    position: "relative",

                    pl: vote && 7,
                    "&.MuiToggleButtonGroup-grouped:not(:first-of-type)": {
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                      borderTopLeftRadius: 4,
                      borderTopRightRadius: 4,
                    },
                    py: 0,
                    pr: 0,
                  }}
                  disabled={vote || loading ? true : false}
                  value={el.id}
                  aria-label={el.id}
                >
                  {!!vote && (
                    <Box
                      sx={{
                        bgcolor: "#000",
                        position: "absolute",
                        left: 0,
                        top: 0,
                        bottom: 0,
                        width: 56,
                        borderRadius: 1,
                        borderBottomRightRadius: 0,
                        borderTopRightRadius: 0,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        p: 0.5,
                      }}
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          fontStyle: "normal",
                          color: "#fff",
                          fontWeight: 600,
                        }}
                      >
                        {percentage(el)}%
                      </Typography>
                    </Box>
                  )}

                  <EllipsisContent
                    variant="body2"
                    sx={{
                      width: 1,
                      fontStyle: "normal",
                      py: 1.5,
                      px: 1,
                      background:
                        vote &&
                        `linear-gradient(to right, ${
                          configData.primary
                        }80 ${percentage(el)}%, transparent ${percentage(
                          el
                        )}%)`,
                    }}
                    text={`${el.option} ${!!vote ? `(${el?.vote})` : ""}`}
                  />
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
          {pollsDetails?.PollType === "multi_select" && (
            <LoadingButton
              loading={loading}
              onClick={() => handleCastVote(view)}
              variant="contained"
              disabled={vote ? true : false}
            >
              Vote
            </LoadingButton>
          )}
        </Box>
      ) : (
        <Typography>No Polls</Typography>
      )}
    </Box>
  );
};
export default PollComponent;
