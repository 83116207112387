import { Box, Typography } from "@mui/material";
import React from "react";
import NAHL from "../../../assets/img/nahl.png";
import NOTEAM from "../../../assets/img/noteam.png";
import PageRoutes, { APIRoutes } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import momentTimezone from "moment-timezone";
// import "./index.css";

import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";
import useConfig from "../../../hooks/useConfig";
import DialogBox from "../DialogBox";
import HttpService from "../../../hooks/Https-services";
const userTimeZone = momentTimezone.tz.guess();

const GameCard = ({
  game,
  auth,
  isSubscribed,
  subscriptionDetails,
}: {
  game: any;
  auth: any;
  isSubscribed: boolean;
  subscriptionDetails: any;
}) => {
  const navigate = useNavigate();
  const configData = useConfig();
  const gaEventTracker = useAnalyticsEventTracker("upcoming-events");
  const handleNavigate = (game: any) => {
    gaEventTracker("watch", `watch${game.game_id}`);
    navigate(PageRoutes.Watch.replace(":gameId", game.game_id));
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const checkGameTimeIsBeetween48Hour = (time: any) => {
    const formatedTime = momentTimezone
      .tz(time, "YYYY-MM-DD hh:mm:ss.SSSSSS a", "America/New_York")
      .tz(userTimeZone);

    const currentTime = moment(moment(), "YYYY-MM-DD hh:mm:ss.SSSSSS a");
    const futureTime = moment(
      moment().add(2, "days"),
      "YYYY-MM-DD hh:mm:ss.SSSSSS a"
    );

    if (formatedTime.isBefore(futureTime)) {
      return true;
    } else {
      return false;
    }
  };
  // You started watching Gold at Blue from 2022-07-11 @ 8:30PM EDT.
  const handleSubmitLogs = async (game: any) => {
    try {
      const data = {
        game_id: game.game_id,
        message: `You started watching ${game.away_team} at ${
          game.home_team
        } from ${moment(
          game.game_date_time,
          "YYYY-MM-DD hh:mm:ss.SSSSSS a Z"
        ).format("YYYY-MM-DD @ hh:mm A")}`,
        eventTyle: "GAME_LOGS",
      };

      const res = await HttpService.post(APIRoutes.GameLogsAdd, data);
    } catch (error) {}
  };
  const edtDateTime = new Date(game?.game_date_time);
  const checkHasShowName = () => {
    const url = new URL(game?.card_image_url); // Create a URL object
    const isShowImage = url.searchParams.get("showNames"); // Get the value of isShowImage
    if (isShowImage === "false" || !isShowImage) {
      return false;
    } else if (isShowImage === "true") {
      return true;
    }
  };
  return (
    <Box
      id={game.game_id}
      sx={{
        width: { xs: 1 },
        paddingTop: "5px",
        borderRadius: "4px",
        mx: "auto",
        maxWidth: { xs: 330 },
        boxShadow: "0 .5rem 1rem rgba(0,0,0,0.15)",
        background: `linear-gradient(110deg,  ${
          game?.away_team_primary_color || configData.secondary
        } 50%,${game?.home_team_primary_color || configData.primary} 50%)`,
      }}
      className="card-update"
    >
      <Box className="heder-card">
        {!!game?.card_image_url && !checkHasShowName() ? (
          <Box></Box>
        ) : (
          <Box>
            <p style={{ color: configData.primary }}>
              {game.away_team} {game.away_team_mascot}
            </p>
            <p style={{ color: configData.primary }}>
              {game.home_team} {game.home_team_mascot}
            </p>
          </Box>
        )}
        <Box>
          {game?.card_logo ? (
            <Box>
              <img
                src={game?.card_logo}
                alt=""
                style={{ width: "80px", height: "50px" }}
              />
            </Box>
          ) : (
            <img
              src={NAHL}
              alt=""
              style={{ width: "80px", height: "50px", visibility: "hidden" }}
            />
          )}
        </Box>
      </Box>
      <Box className="subtitle">
        {game?.card_description ? (
          <>
            <p>{game?.card_description}</p>
          </>
        ) : (
          <p>REGULAR SEASON GAME</p>
        )}
      </Box>
      {!!game?.card_image_url ? (
        <Box
          className="teamName"
          sx={{
            display: "flex",
            justifyContent: "center",
            p: 0.5,
          }}
        >
          <Box
            component={"img"}
            sx={{
              borderRadius: "8px",
              width: "100%",
              objectFit: "cover",
              maxHeight: { xs: "auto", md: "105px" },
              height: 1,
            }}
            alt={game?.game_id}
            src={game?.card_image_url}
          />
        </Box>
      ) : (
        <Box className="teamName">
          <Box
            style={{ width: "80px", display: "flex", justifyContent: "end" }}
          >
            {game.away_team_logo ? (
              <img
                className="teamLogo"
                width="90%"
                src={game.away_team_logo}
                alt=""
              />
            ) : (
              <img className="teamLogo" width="90%" src={NOTEAM} alt="" />
            )}
          </Box>

          <Box>AT</Box>
          <Box style={{ width: "80px" }}>
            {game.home_team_logo ? (
              <img
                className="teamLogo"
                width="90%"
                src={game.home_team_logo}
                alt=""
              />
            ) : (
              <img className="teamLogo" width="90%" src={NOTEAM} alt="" />
            )}
          </Box>
        </Box>
      )}

      <Box className="footer-card">
        <Box>
          <p style={{ fontSize: "14px", color: "white" }}>
            {momentTimezone
              .tz(
                game.game_date_time,
                "YYYY-MM-DD hh:mm:ss.SSSSSS a Z",
                "America/New_York"
              )
              .tz(userTimeZone)
              .format("MMM DD YYYY")}
          </p>
          <p style={{ fontSize: "12px", color: "white" }}>
            {momentTimezone
              .tz(
                game.game_date_time,
                "YYYY-MM-DD hh:mm:ss.SSSSSS a Z",
                "America/New_York"
              )
              .tz(userTimeZone)
              .format("h:mm a z")}{" "}
          </p>
        </Box>
        {game?.game_status == 90 ? (
          <Typography
            variant="body2"
            sx={{ color: configData?.tertiary, fontWeight: 600 }}
          >
            Broadcast Canceled
          </Typography>
        ) : game?.game_status == 95 ? (
          <Typography
            variant="body2"
            sx={{ color: configData?.tertiary, fontWeight: 600 }}
          >
            Game Canceled
          </Typography>
        ) : (
          <Box>
            {(isSubscribed || game?.subscribe) &&
            !subscriptionDetails?.pause_collection ? (
              <button
                className="card-footer-button"
                style={{ color: configData.primary }}
                onClick={() => {
                  handleNavigate(game);
                  handleSubmitLogs(game);
                }}
              >
                WATCH
              </button>
            ) : !auth?.AccessToken && game.monetization_type == 1 ? (
              <button
                className="card-footer-button"
                style={{
                  whiteSpace: "nowrap",
                  width: "auto",
                  color: configData.primary,
                  padding: "0px 8px",
                }}
                onClick={() => {
                  navigate(PageRoutes.Login);
                }}
              >
                WATCH FREE
              </button>
            ) : game.monetization_type == 0 ? (
              <button
                style={{ color: configData.primary }}
                className="card-footer-button"
                onClick={() => {
                  if (auth.AccessToken) {
                    checkGameTimeIsBeetween48Hour(game.game_date_time)
                      ? navigate(`${PageRoutes.Packages}?id=${game.game_id}`)
                      : handleClickOpen();
                  } else {
                    navigate(PageRoutes.Login);
                  }
                }}
              >
                BUY EVENT
              </button>
            ) : (
              <button
                style={{ color: configData.primary }}
                className="card-footer-button"
                onClick={() => {
                  handleNavigate(game);
                  handleSubmitLogs(game);
                }}
              >
                WATCH
              </button>
            )}
          </Box>
        )}

        <DialogBox
          open={open}
          handleClose={handleClose}
          buttonAction={() => handleClose()}
          buttonText={"Close"}
          content={
            "You can only buy a single game within 48 hours of game time."
          }
        />
      </Box>
    </Box>
  );
};

export default GameCard;
