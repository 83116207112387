import { LoadingButton } from "@mui/lab";
import {
  Box,
  debounce,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import HttpService from "../../../../hooks/Https-services";
import { APIRoutes } from "../../../../utils/constants";
import { KeyboardArrowRight, KeyboardArrowLeft } from "@mui/icons-material";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";

const monthList = Array.from({ length: 4 }, (_, index) => {
  const month = moment().subtract(index, "months"); // Subtract months from the current date
  return {
    id: month.format("MM"), // ID in reverse order (12, 11, ..., 1)
    value: month.format(`MMMM YYYY`), // Format to 'Month Year'
    year: month.format(`YYYY`),
  };
});
export interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

export function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const ViewReport = () => {
  const [month, setMonth] = React.useState(moment().format("MM"));

  const [allReports, setAllReports] = React.useState({} as any);
  const [keyword, setKeyword] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDownloading, setIsDownloading] = React.useState(false);
  const navigate = useNavigate();
  // Avoid a layout jump when reaching the last page with empty rows.
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allReports?.total) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const GetAllReports = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const findYear = monthList.find((ele: any) => ele.id == month);
      const res = await HttpService.get(
        `${APIRoutes.GetViewReport}?month=${month}&page=${
          page + 1
        }&limit=${rowsPerPage}&year=${findYear?.year}`
      );
      setAllReports(res?.data?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [month, page, rowsPerPage]);
  const handleKeywordChange = debounce((value: string) => {
    setKeyword(value);
  }, 300); //
  React.useEffect(() => {
    GetAllReports();
  }, [GetAllReports]);
  const DownloadAllReports = async () => {
    try {
      setIsDownloading(true);
      const findYear = monthList.find((ele: any) => ele.id == month);
      const res = await HttpService.get(
        `${APIRoutes.GetViewReport}?month=${month}&isDownload=true&year=${findYear?.year}`
      );
      downloadJSONAsCSV(res?.data?.data?.rows);
      setIsDownloading(false);
    } catch (error) {
      setIsDownloading(false);
    }
  };
  function jsonToCSV(jsonData: any) {
    // Get the headers
    const headers = Object.keys(jsonData[0]).join(",") + "\n";

    // Get the rows
    const rows = jsonData
      .map((row: any) =>
        Object.values({
          game_id: row?.GameID,
          date: row?.game_date_time?.value, // Access the nested value
          away_team: row?.away_team,
          home_team: row?.home_team,
          live: row?.live,
          liveAway: row?.liveAway,
          vod: row?.vod,
          vodAway: row?.vodAway,
          minutesWatched: row?.minutesWatched,
          minutesWatchedAway: row?.minutesWatchedAway,
        }).join(",")
      )
      .join("\n");

    return headers + rows;
  }
  function downloadJSONAsCSV(jsonData: any, filename = "reports.csv") {
    // Convert JSON to CSV
    const csv = jsonToCSV(jsonData);

    // Create a Blob from the CSV string
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    // Create a link to download the Blob
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", py: 2 }}>
        <Select
          size="small"
          value={month}
          onChange={(e) => setMonth(e.target.value)}
        >
          {monthList.map((el) => (
            <MenuItem value={el.id} key={el.id}>
              {el.value}
            </MenuItem>
          ))}
        </Select>
        <LoadingButton
          onClick={DownloadAllReports}
          loading={isDownloading}
          size="large"
          variant="contained"
        >
          Download CSV
        </LoadingButton>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: "normal",
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Game ID
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: "normal",
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Game Date
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: "normal",
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Away Team
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: "normal",
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Home Team
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: "normal",
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Live
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: "normal",
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Live Away
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: "normal",
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Vod
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: "normal",
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Vod Away
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: "normal",
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Total Minute Watched
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: "normal",
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Total Minute Watched Away
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableBody>
              {new Array(10).fill(0).map((el) => {
                return (
                  <TableRow>
                    <TableCell>
                      <Skeleton variant={"rounded"} sx={{ p: 2 }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant={"rounded"} sx={{ p: 2 }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant={"rounded"} sx={{ p: 2 }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant={"rounded"} sx={{ p: 2 }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant={"rounded"} sx={{ p: 2 }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant={"rounded"} sx={{ p: 2 }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant={"rounded"} sx={{ p: 2 }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant={"rounded"} sx={{ p: 2 }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant={"rounded"} sx={{ p: 2 }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant={"rounded"} sx={{ p: 2 }} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <TableBody>
              {allReports?.rows?.map((row: any) => (
                <TableRow key={row.GameID}>
                  <TableCell component="th" scope="row">
                    <Typography
                      variant="body2"
                      sx={{ fontStyle: "normal", textTransform: "none" }}
                    >
                      {row.GameID}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    <Typography
                      variant="body2"
                      sx={{ fontStyle: "normal", textTransform: "none" }}
                    >
                      {moment(row?.game_date_time?.value).format(
                        "DD MMM YYYY hh:mm A"
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    <Typography
                      variant="body2"
                      sx={{ fontStyle: "normal", textTransform: "none" }}
                    >
                      {row?.away_team}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    <Typography
                      variant="body2"
                      sx={{ fontStyle: "normal", textTransform: "none" }}
                    >
                      {row?.home_team}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="center">
                    <Typography
                      variant="body2"
                      sx={{ fontStyle: "normal", textTransform: "none" }}
                    >
                      {row?.live}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    <Typography
                      variant="body2"
                      sx={{
                        fontStyle: "normal",
                        textTransform: "none",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {row?.liveAway}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    <Typography
                      variant="body2"
                      sx={{
                        fontStyle: "normal",
                        textTransform: "none",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {row?.vod}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    <Typography
                      variant="body2"
                      sx={{
                        fontStyle: "normal",
                        textTransform: "none",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {row?.vodAway}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    <Typography
                      variant="body2"
                      sx={{
                        fontStyle: "normal",
                        textTransform: "none",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {row?.minutesWatched}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    <Typography
                      variant="body2"
                      sx={{
                        fontStyle: "normal",
                        textTransform: "none",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {row?.minutesWatchedAway || 0}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        colSpan={3}
        rowsPerPageOptions={[10, 20, 30]}
        count={allReports.total}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </Box>
  );
};
export default ViewReport;
