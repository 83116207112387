import React, { useCallback, useEffect, useState } from "react";
import { getCurrentAuth } from "../../../hooks/useAxiosPrivate";
import PageRoutes, { APIRoutes } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import useConfig from "../../../hooks/useConfig";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { toast } from "react-toastify";
import HttpService from "../../../hooks/Https-services";
//@ts-ignore
import Style from "../UpgradeModel/index.module.css";
import { LoadingButton } from "@mui/lab";
const UpgradeModel = ({
  el,
  currentPlan,
  handleUpgrade,
  isSkeleton,
  isLoading,
}: any) => {
  const auth = getCurrentAuth();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const navigate = useNavigate();
  const configData = useConfig();
  const [coupenCode, setCoupenCode] = useState("");
  const [newSubDetails, setNewSubDetails] = useState<any>({});
  const [pendingAmount, setPendingAmount] = useState<any>({});
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setCoupenCode("");
    setNewSubDetails(null);
    setPendingAmount(null);
  };
  const handleApplyCode = async () => {
    if (!coupenCode) {
      toast.error("Please enter a valid code");
      return;
    }
    try {
      setIsButtonLoading(true);
      const res = await HttpService.post(APIRoutes.ApplyCodeUpgrade, {
        couponCode: coupenCode,
        subscriptionId: currentPlan?.subscription?.id,
        customerId: currentPlan?.costomer_id,

        newPriceId: el?.PlanID,
        subItemId: currentPlan?.subscription?.items?.data[0]?.id,
      });
      if (res) {
        setNewSubDetails(res?.data?.data);
        toast.success("Coupen Code Applied Successfully");
        setIsButtonLoading(false);
      }
    } catch (error: any) {
      setIsButtonLoading(false);
      toast.error(error.response.data.message);
    }
  };
  const GetRemainingPrice = useCallback(async () => {
    try {
      if (currentPlan && el && open) {
        const res = await HttpService.post(APIRoutes.RetrivePendingPrice, {
          subscriptionId: currentPlan?.subscription?.id,
          customerId: currentPlan?.costomer_id,

          newPriceId: el?.PlanID,
          subItemId: currentPlan?.subscription?.items?.data[0]?.id,
        });
        setPendingAmount(res?.data?.data?.cost);
      }
    } catch (error) {}
  }, [currentPlan, el, open]);
  useEffect(() => {
    GetRemainingPrice();
  }, [GetRemainingPrice]);
  return (
    <div className="pricing-action">
      <button
        onClick={handleOpen}
        disabled={
          isSkeleton ||
          !el?.Status ||
          parseInt(el?.PriceInCurrency) <
            parseInt(currentPlan?.price_in_currency) ||
          currentPlan?.subscription?.pause_collection
        }
        style={{
          cursor: "pointer",
          visibility:
            parseInt(el?.PriceInCurrency) <
            parseInt(currentPlan?.price_in_currency)
              ? "hidden"
              : "visible",
        }}
        className={configData.buttonCommonClass}
      >
        Upgrade
      </button>{" "}
      <Dialog
        onClose={handleClose}
        className={Style.dialogStyle}
        sx={{
          width: 1,
          ".MuiDialog-paper": {
            width: 1,
          },
        }}
        maxWidth="sm"
        open={open}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h3">Apply Coupon Code</Typography>
          <IconButton onClick={handleClose}>
            <Cancel />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box sx={{ mt: 2 }}>
              <Typography variant="body1" gutterBottom>
                You are about to upgrade your Plan and your credit card will be
                charged the pro-rated rate today.
              </Typography>
            </Box>

            <Box
              sx={{
                py: 1,
                display: "flex",
                flexDirection: "row",
                gap: 0.5,
              }}
            >
              <TextField
                onChange={(e: any) => setCoupenCode(e.target.value)}
                size="small"
                disabled={newSubDetails?.coupon?.id}
                value={coupenCode}
                helperText={
                  "Please enter a Coupon Code if you have one.  If not, please leave the box blank."
                }
                placeholder="Apply Coupon Code"
              />
              <div className="value">
                <LoadingButton
                  disabled={newSubDetails?.coupon?.id}
                  loading={isButtonLoading}
                  onClick={handleApplyCode}
                  sx={{
                    ":hover": {
                      bgcolor: configData?.primary,
                    },
                  }}
                  style={{ width: "auto", fontSize: "16px" }}
                  variant="contained"
                >
                  Apply
                </LoadingButton>
              </div>
            </Box>
            <Box>
              <Box>
                <Typography
                  variant="body1"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: 1,
                  }}
                  gutterBottom
                >
                  Amount{" "}
                  <span>
                    {" "}
                    ${((pendingAmount?.total || 0) / 100)?.toFixed(2)}
                  </span>
                </Typography>
                {!!newSubDetails?.coupon && (
                  <Typography
                    variant="body1"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: 1,
                    }}
                    gutterBottom
                  >
                    Discount
                    <Typography
                      color={"success"}
                      sx={{ color: "green" }}
                      variant="caption"
                    >
                      {newSubDetails?.coupon.id} applied ( -
                      {!!newSubDetails?.coupon.amount_off &&
                        `${newSubDetails?.coupon.amount_off / 100}`}
                      {!!newSubDetails?.coupon.percent_off &&
                        `${newSubDetails?.coupon.percent_off}%`}
                      )
                    </Typography>
                  </Typography>
                )}
                <Divider sx={{ border: "1px solid", my: 1 }} />
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: 1,
                  }}
                  variant="body1"
                  gutterBottom
                >
                  Total{" "}
                  <span>
                    $
                    {newSubDetails?.coupon
                      ? ((newSubDetails?.cost?.total || 0) / 100).toFixed(2)
                      : ((pendingAmount?.total || 0) / 100).toFixed(2)}
                  </span>
                </Typography>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ gap: 1 }}>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Box>
            {" "}
            <Button
              variant="contained"
              sx={{
                ":hover": {
                  bgcolor: configData?.primary,
                },
              }}
              onClick={() => {
                handleClose();
                if (
                  isLoading ||
                  isSkeleton ||
                  !el?.Status ||
                  parseInt(el?.PriceInCurrency) <
                    parseInt(currentPlan?.price_in_currency) ||
                  currentPlan?.subscription?.pause_collection
                ) {
                  return;
                } else {
                  if (!auth.AccessToken) {
                    navigate(PageRoutes.Login);
                  } else {
                    handleUpgrade(el, newSubDetails?.coupon?.id);
                  }
                }
              }}
            >
              Upgrade
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default UpgradeModel;
