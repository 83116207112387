import * as React from "react";
import Box from "@mui/material/Box";
import { Tab, Tabs, Typography } from "@mui/material";

import _ from "lodash";
import { a11yProps, TabPanel } from "../Ads";
import ViewReport from "./ViewReport";
import GaAnalystic from "./GaAnalystic";

export default function Reports() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", py: 2 }}>
        <Typography variant="h3">Reports</Typography>
      </Box>
      <Tabs value={value} onChange={handleChange} sx={{ px: 3 }}>
        <Tab label="Viewership" {...a11yProps(0)} />
        <Tab label="Google Analytics" {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <ViewReport />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <GaAnalystic />
      </TabPanel>
    </Box>
  );
}
