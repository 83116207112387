import { Box, CircularProgress } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import HttpService from "../../../../hooks/Https-services";
import PageRoutes, { APIRoutes } from "../../../../utils/constants";

const GaAnalystic = () => {
  const [urlData, setUrlData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);
  const GetGAUrl = useCallback(async () => {
    try {
      const res = await HttpService.get(
        APIRoutes.GetAnalyticsUrl.replace(":key", "ga")
      );
      setUrlData(res.data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    GetGAUrl();
  }, [GetGAUrl]);
  if (isLoading) {
    return <CircularProgress />;
  }
  return (
    <Box sx={{ height: "calc( 100vh + 100px )" }}>
      {urlData?.Url ? (
        <Box sx={{ height: "calc( 100vh + 100px )" }}>
          {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
          <iframe
            width="100%"
            src={urlData?.Url}
            style={{ border: "0px", height: "100%" }}
            sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
          ></iframe>
        </Box>
      ) : (
        <Box></Box>
      )}
    </Box>
  );
};
export default GaAnalystic;
