import React, { useState } from "react";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

//@ts-ignore

import { PlayerModule as AnalyticsModule } from "bitmovin-analytics";
import { PlayerEvent, Player, ViewMode } from "bitmovin-player";

import "bitmovin-player/bitmovinplayer-ui.css";

//@ts-ignore

import { Box, useMediaQuery } from "@mui/material";
import { url } from "inspector";
import useCommonDetails from "../../Common/Hooks/useCommonDetails";
import useConfig from "../../hooks/useConfig";
import { toast } from "react-toastify";
import { APIRoutes } from "../../utils/constants";
import { PersonalChannelApi } from "../PersonalChannel/PersonalChannel.Api";

declare global {
  interface Window {
    bitmovin: any;
  }
}

interface bitMovingPlayerProps {
  playerRef: any;
  currentVideoData: any;
  videoList: any[];
  videoUrl: string;
  setPage: any;
  page: any;
  setCurrentVideo: any;
  pageData: any;
}
const HighlightPlayer: React.FC<bitMovingPlayerProps> = ({
  playerRef,
  currentVideoData,
  videoList,
  setPage,
  page,
  videoUrl,
  setCurrentVideo,
  pageData,
}) => {
  const userDetails = useCommonDetails();
  const configData = useConfig();
  const { id } = useParams();
  const playerId = configData.jwPlayerID;
  // Event handler for seek end

  useEffect(() => {
    // Function to initialize JW Player
    if (videoUrl) {
      var conf: any = {
        key: configData?.bitMovinKey,
        location: {
          ui: "https://cdn.bitmovin.com/player/web/8/bitmovinplayer-ui.js",
          ui_css: "https://cdn.bitmovin.com/player/web/8/bitmovinplayer-ui.css",
        },

        analytics: {
          key: configData.analysticBitmovinKey,

          title: `${currentVideoData?.Title} - ${currentVideoData?.Description}`,

          videoId: `highlight_${currentVideoData?.ID}`,
          customUserId: userDetails.UserID,
          customVideoType: "Highlight",
        },

        playback: {
          autoplay: true,
        },
        cast: {
          enable: true,
        },
      };

      var source = {
        hls: videoUrl,
      };
      Player.addModule(AnalyticsModule);
      playerRef.current = new Player(
        document.getElementById("linus-bitmovin-player") as any,
        conf
      );

      // Setup the player
      playerRef.current.load(source).then(() => {});

      playerRef.current.on(PlayerEvent.PlaybackFinished, () => {
        const findIndex = videoList.findIndex(
          (el: any) => el.ID == currentVideoData?.ID
        );
        if (
          pageData.totalPage === pageData.page &&
          findIndex === videoList.length - 1
        ) {
          setPage(1);
          return;
        } else if (findIndex === videoList.length - 1) {
          setPage(page + 1);

          return;
        } else {
          setCurrentVideo(videoList[findIndex + 1]);
          return;
        }
      });

      return () => {
        playerRef.current.unload();
      };
    }
  }, [playerId, videoUrl, videoList, currentVideoData, id]);

  return (
    <Box sx={{ position: "relative" }}>
      <Box className="player-container" id={"linus-bitmovin-player"}></Box>
    </Box>
  );
};

export default HighlightPlayer;
