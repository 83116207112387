import { Settings } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { Formik, Form, FormikValues, FormikProps } from "formik";
import * as Yup from "yup";
import useConfig from "../../../../hooks/useConfig";
import HttpService from "../../../../hooks/Https-services";
import { APIRoutes } from "../../../../utils/constants";
import { toast } from "react-toastify";

const validationSchema = Yup.object({
  channels: Yup.array().test(
    "thumbnail-required",
    "Please select at least one channel",
    function (value) {
      if (!value || value.length === 0) {
        return false;
      }

      const hasEmptyThumbnail = value.some((channel) => !channel.thumbnail);
      console.log(hasEmptyThumbnail, "k");
      if (hasEmptyThumbnail) {
        throw this.createError({
          message: "Thumbnail is required for all selected channels",
        });
      }

      return true;
    }
  ),
});
const WizzardSettings = () => {
  const [open, setOpen] = useState(false);
  const configData = useConfig();
  const [initialValues, setInitialValues] = useState<any>({ channels: [] });
  const [channelOption, setChannelOption] = useState<any[]>([]);

  const formikRef = useRef<FormikProps<any>>(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async () => {
    setOpen(false);
  };

  const handleSubmit = async (values: FormikValues) => {
    try {
      const result = await HttpService.post(APIRoutes.UpdateTenantConfig, {
        wizzard_settings: values,
      });
      if (result.data) {
        toast.success("Wizzard Settings Updated Successfully");
        handleClose();
      }
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
    // Handle form submission
  };

  const FetchChannels = useCallback(async () => {
    if (!open) return;
    try {
      const result = await HttpService.getapi(
        APIRoutes.ChannelOption.replace(":id", configData.customer)
      );

      setChannelOption(result.data.data);
    } catch (error) {}
  }, [open]);

  const FetchTenantConfig = useCallback(async () => {
    if (!open) return;
    try {
      const result = await HttpService.get(APIRoutes.getTenantConfig);
      const data = result.data.data;
      console.log(data);
      setInitialValues(
        result.data?.data?.TenantDetails?.wizzard_settings || { channels: [] }
      );
    } catch (error) {}
  }, [open]);

  useEffect(() => {
    FetchChannels();
    FetchTenantConfig();
  }, [FetchChannels, FetchTenantConfig]);

  return (
    <Box>
      <IconButton onClick={handleClickOpen}>
        <Settings />
      </IconButton>
      <Dialog
        maxWidth={"md"}
        sx={{
          width: "100%",
          ".MuiDialog-paper": {
            width: "100%",
          },
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h6" sx={{ color: "#000" }}>
            Wizzard Settings
          </Typography>
        </DialogTitle>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
          validateOnChange={true}
          validateOnMount
        >
          {({
            values,
            isSubmitting,
            setFieldValue,
            errors,
            touched,
            isValid,
          }: any) => (
            <Form>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Autocomplete
                        // disabled={isSubmitting}
                        multiple
                        id="tags-outlined"
                        options={channelOption}
                        getOptionLabel={(option) => option.channel_name}
                        filterSelectedOptions
                        value={values?.channels || []}
                        isOptionEqualToValue={(option, value) =>
                          option.channel_id === value.channel_id
                        }
                        onChange={(event, newValue) => {
                          // Preserve thumbnails for existing channels
                          const updatedChannels = newValue.map((option) => {
                            // Find if this channel already exists in the current values
                            const existingChannel = values?.channels?.find(
                              (ch: any) => ch.channel_id === option.channel_id
                            );

                            // If channel exists, keep its existing thumbnail
                            // Otherwise, set thumbnail to empty string
                            return {
                              ...option,
                              thumbnail: existingChannel?.thumbnail || "",
                            };
                          });

                          setFieldValue("channels", updatedChannels);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Channels"
                            placeholder="Channels"
                            error={
                              touched?.channels && Boolean(errors?.channels)
                            }
                            helperText={
                              touched?.channels && errors?.channels?.toString()
                            }
                          />
                        )}
                      />
                    </Grid>

                    {values?.channels &&
                      values?.channels?.map((channel: any, index: number) => (
                        <Grid
                          container
                          item
                          xs={12}
                          spacing={2}
                          key={channel?.channel_id}
                        >
                          <Grid item xs={12} md={4}>
                            <TextField
                              fullWidth
                              disabled
                              value={channel?.channel_name}
                              label="Channel Name"
                            />
                          </Grid>
                          <Grid item xs={12} md={8}>
                            <TextField
                              fullWidth
                              label="Thumbnail URL"
                              value={channel?.thumbnail || ""}
                              onChange={(e) => {
                                const updatedChannels = [...values?.channels];
                                updatedChannels[index] = {
                                  ...updatedChannels[index],
                                  thumbnail: e.target.value,
                                };
                                setFieldValue("channels", updatedChannels);
                              }}
                              error={Boolean(
                                touched?.channels?.[index]?.thumbnail &&
                                  errors?.channels?.[index]?.thumbnail
                              )}
                              helperText={
                                touched?.channels?.[index]?.thumbnail &&
                                errors?.channels?.[index]?.thumbnail
                              }
                            />
                          </Grid>
                        </Grid>
                      ))}
                  </Grid>
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button variant="outlined" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting || !isValid}
                >
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Box>
  );
};

export default WizzardSettings;
