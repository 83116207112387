import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import PageRoutes, { APIRoutes } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import LoadingContext from "../../../Common/Contex/LoadingContex";
import HttpService from "../../../hooks/Https-services";
import useConfig from "../../../hooks/useConfig";
import WhizzardTable from "./WhizzardTable";
import WizzardSettings from "./WizzardSettings";

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ width: "100%" }}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const ListOfWhizzard = () => {
  const [listOfWhizzard, setListOfWhizzard] = useState<any>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [pageData, setPageData] = React.useState({
    page: 0,
    total_entries: 0,
    limit: 5,
  });
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setPage(0);
    setValue(newValue);
  };
  const navigate = useNavigate();
  const configData = useConfig();

  const GetWhizzard = useCallback(async () => {
    try {
      const res = await HttpService.get(
        `${APIRoutes.GetWhizzardsAdmin}?isActive=${
          value === 0 ? true : false
        }&page=${page + 1}&per_page=${rowsPerPage}`
      );
      setListOfWhizzard(res?.data?.data?.highlights);
      const data = res?.data?.data;
      setPageData({
        ...pageData,
        page: data.current_page,
        limit: data.per_page,
        total_entries: data.total_entries,
      });
    } catch (error) {}
  }, [value, page, rowsPerPage]);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    GetWhizzard();
  }, [GetWhizzard]);

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", py: 2 }}>
        <Typography variant="h3">Whizzards</Typography>

        <Box sx={{ display: "flex", gap: 2 }}>
          <Box>
            <WizzardSettings />
          </Box>
          <Box>
            <button
              onClick={() =>
                navigate(PageRoutes.EditWhizzard.replace(":id", "0"))
              }
              style={{
                cursor: "pointer",
              }}
              className={configData.buttonCommonClass}
            >
              Add Whizzard
            </button>{" "}
          </Box>
        </Box>
      </Box>
      <Box>
        <Tabs value={value} onChange={handleChange} sx={{ px: 3 }}>
          <Tab label="Available" {...a11yProps(0)} />
          <Tab label="Trash" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <WhizzardTable
            listOfWhizzard={listOfWhizzard}
            pageData={pageData}
            GetWhizzard={GetWhizzard}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <WhizzardTable
            pageData={pageData}
            page={page}
            rowsPerPage={rowsPerPage}
            listOfWhizzard={listOfWhizzard}
            GetWhizzard={GetWhizzard}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TabPanel>
      </Box>
    </Box>
  );
};
export default ListOfWhizzard;
