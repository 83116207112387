import {
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useConfig from "../../../hooks/useConfig";
import HttpService from "../../../hooks/Https-services";
import PageRoutes, {
  APIRoutes,
  INSIDER_PLAN_ID,
  UserRoles,
} from "../../../utils/constants";
import useCommonDetails from "../../../Common/Hooks/useCommonDetails";
import { useNavigate, useParams } from "react-router-dom";
import { getCurrentAuth } from "../../../hooks/http-server";
import ShortContentCard from "../ShortContentCard";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Grid2 from "@mui/material/Unstable_Grid2";
const ChannelHighLights = ({
  hasPermition,
  channel_id,
  type,
  type_name,
  channel_name,
}: any) => {
  const configData = useConfig();
  const auth = getCurrentAuth();
  const userDetails = useCommonDetails();
  const navigate = useNavigate();
  const [highlights, setHighlights] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const GetHighLights = useCallback(async () => {
    if (channel_id) {
      try {
        const queryP = {
          channel_id: channel_id,
          type: type,
        };
        const res = await HttpService.get(
          APIRoutes.GetWhizzardsV2 +
            "?" +
            new URLSearchParams(queryP).toString()
        );
        if (res) {
          setHighlights(res.data?.data?.wizzards);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  }, [channel_id, type]);
  useEffect(() => {
    GetHighLights();
    // Make API call here
  }, [GetHighLights]);

  const PreviousBtn = (props: any) => {
    // console.log(props);
    const { className, onClick } = props;
    return (
      <Box
        className={className}
        sx={{ position: "absolute", top: "50%", bottom: "50%" }}
      >
        <IconButton
          onClick={onClick}
          sx={{
            bgcolor: configData.primary,
            border: "1px solid #E7E7E7",
            p: 0.5,
            zIndex: 10,
            ":hover": {
              bgcolor: configData.primary,
            },
          }}
        >
          <ArrowBackIcon
            sx={{ fontSize: "16px", color: configData.tertiary }}
          />
        </IconButton>
      </Box>
    );
  };
  const NextBtn = (props: any) => {
    const { className, onClick } = props;
    return (
      <Box className={className} sx={{ position: "absolute", top: "50%" }}>
        <IconButton
          onClick={onClick}
          sx={{
            border: "1px solid #E7E7E7",
            rotate: "180deg",
            p: 0.5,
            bgcolor: configData.primary,
            ":hover": {
              bgcolor: configData.primary,
            },
          }}
        >
          <ArrowBackIcon
            sx={{ fontSize: "16px", color: configData.tertiary }}
          />
        </IconButton>
      </Box>
    );
  };
  const isMobile = useMediaQuery((theme: any) =>
    theme.breakpoints.down("1440")
  );
  const [sliderRef, setSliderRef] = useState<any>(null);
  //  xs: 0,
  //       sm: 567,
  //       md: 768,
  //       lg: 992,
  //       xl: 1440,
  const sliderSettings = {
    slidesToShow: 4,
    dots: true,
    autoplay: false,
    autoplaySpeed: 2500,
    slidesToScroll: 4,
    infinite: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: highlights?.length > 3 ? 3 : highlights?.length,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
    ],
  };
  if (isLoading) {
    return (
      <Container
        maxWidth="xl"
        sx={{ position: "relative", px: "0px !important" }}
      >
        <Grid2 container spacing={2}>
          <Grid2 xs={12} sm={12} md={6} lg={4} xl={3}>
            <Skeleton variant="rectangular" height={250} />
          </Grid2>
          <Grid2
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "block",
              },
            }}
            xs={12}
            sm={12}
            md={6}
            lg={4}
            xl={3}
          >
            <Skeleton variant="rectangular" height={250} />
          </Grid2>
          <Grid2
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "none",
                lg: "block",
              },
            }}
            xs={12}
            sm={12}
            md={6}
            lg={4}
            xl={3}
          >
            <Skeleton variant="rectangular" height={250} />
          </Grid2>
          <Grid2
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "none",
                lg: "none",
                xl: "block",
              },
            }}
            xs={12}
            sm={12}
            md={6}
            lg={4}
            xl={3}
          >
            <Skeleton variant="rectangular" height={250} />
          </Grid2>
        </Grid2>
      </Container>
    );
  }
  return (
    <Box>
      <Container
        maxWidth="xl"
        sx={{ position: "relative", pb: 5, px: "0px !important" }}
      >
        <Box>
          <Typography
            variant="h3"
            onClick={() =>
              navigate(
                PageRoutes.FilterShortContent.replace(":channel", channel_name)
                  .replace(":type_name", type_name)
                  .replace(":id", channel_id)
                  .replace(":type", type)
              )
            }
            sx={{
              textTransform: "capitalize",
              fontStyle: "normal",
              fontWeight: 600,
              textDecoration: "none",
              transition: "text-decoration 0.3s ease-in-out",
              "&:hover": {
                textDecoration: "underline",
                cursor: "pointer",
              },
              px: 1,
              py: 2,
            }}
          >
            {channel_name} {type_name} <ArrowForwardIosIcon />
          </Typography>
          {!!highlights?.length ? (
            <Box sx={{ mb: 2 }}>
              <Box>
                <Slider
                  ref={setSliderRef}
                  {...sliderSettings}
                  prevArrow={<PreviousBtn />}
                  nextArrow={<NextBtn />}
                  dotsClass="slick-dots custom-indicator"
                >
                  {highlights.map((highlight: any) => (
                    <Box
                      onClick={() =>
                        navigate(
                          PageRoutes.ShortView.replace(":id", highlight.ID)
                        )
                      }
                    >
                      <ShortContentCard clip={highlight} />
                    </Box>
                  ))}
                  {highlights?.length === 3 && !isMobile && <Box></Box>}
                  {highlights?.length === 2 &&
                    !isMobile &&
                    new Array(2).fill(0).map((el) => <Box></Box>)}
                  {highlights?.length === 1 &&
                    !isMobile &&
                    new Array(3).fill(0).map((el) => <Box></Box>)}
                </Slider>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                textAlign: "start",
                px: 3,
                height: 260,
                bgcolor: "transparent",
                display: "flex",
                borderRadius: "10px",
                border: "1px solid #E7E7E7",
                boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  textTransform: "none",
                  fontStyle: "normal",
                  textAlign: "center",
                }}
              >
                There are no highlights right now
              </Typography>
            </Box>
          )}
        </Box>
      </Container>
      <Divider />
    </Box>
  );
};
export default ChannelHighLights;
