import { Box, Button, CircularProgress, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate, useParams } from "react-router-dom";
import useCommonDetails from "../../../Common/Hooks/useCommonDetails";
import HttpService from "../../../hooks/Https-services";
import { getCurrentAuth } from "../../../hooks/useAxiosPrivate";
import useConfig from "../../../hooks/useConfig";
import PageRoutes, {
  APIRoutes,
  INSIDER_PLAN_ID,
  UserRoles,
} from "../../../utils/constants";
import ShortContentCard from "../ShortContentCard";
import React from "react";

const FilterShortFormContent = () => {
  const [clips, setClips] = useState<any>(null);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [videoList, setVideoList] = useState<any>([]);
  const navigate = useNavigate();
  const [subscriptionDetails, setSubscriptionDetails] = useState<any>({});
  const [slider, setSlider] = useState([]);
  const configData = useConfig();
  const [planDetails, setPlanDetails] = useState<any>(null);
  const userDetails = useCommonDetails();
  const auth = getCurrentAuth();
  const { id, type, channel, type_name } = useParams();
  const [mainLoader, setMainLoader] = useState(true);
  const GetUserSubscriptionData = useCallback(async () => {
    if (!auth.AccessToken) {
      setMainLoader(false);

      return;
    }
    try {
      const res = await HttpService.get(APIRoutes.GetSubscriptionDetials);

      if (res?.data?.data) {
        setSubscriptionDetails(res?.data?.data?.subscription);
        setPlanDetails(res?.data?.data);
        setMainLoader(false);
        return res?.data?.data;
      }
      setMainLoader(false);
    } catch (error) {
      setMainLoader(false);
    }
  }, []);
  useEffect(() => {
    if (auth.AccessToken) {
      GetUserSubscriptionData();
    }
  }, [GetUserSubscriptionData, auth.AccessToken]);
  const GetClips = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await HttpService.get(
        APIRoutes.GetWhizzardsV2 + `?page=${page}&channel_id=${id}&type=${type}`
      );
      setClips(response.data?.data);
      setVideoList([...videoList, ...response.data?.data?.wizzards]);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [page, id, type]);
  useEffect(() => {
    GetClips();
  }, [GetClips]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const hasPermition = () => {
    if (
      (planDetails?.plan_type_id == INSIDER_PLAN_ID &&
        !subscriptionDetails?.pause_collection) ||
      [
        UserRoles.HTP_ADMIN,
        UserRoles.LEAGUE_ADMIN,
        UserRoles.FREE_WATCH_D,
      ].includes(userDetails?.UserRoleID)
    ) {
      return true;
    } else {
      return false;
    }
  };
  if (mainLoader) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Box sx={{ p: 4 }}>
      <Box>
        <Typography variant="h3" sx={{ fontWeight: 600 }}>
          {channel} {type_name}
        </Typography>
        <InfiniteScroll
          dataLength={videoList?.length || 0}
          next={() => {
            if (hasPermition() && videoList?.length > 15) {
              setPage(page + 1);
            }
          }}
          hasMore={hasMore}
          style={{ overflow: "hidden" }}
          loader={
            isLoading && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  py: 1,
                  height: 1,
                  overflow: "hidden",
                }}
              >
                <CircularProgress
                  size={60}
                  sx={{
                    color: "#bf311a",
                  }}
                />
              </Box>
            )
          }
        >
          <Box
            sx={{
              mt: 2,

              opacity: !hasPermition() ? 0.3 : 1,
              backgroundColor: !hasPermition() ? configData.primary : "",
              filter: !hasPermition() ? "blur(1px)" : "",
            }}
          >
            <Grid2 container spacing={2} rowSpacing={2} columnSpacing={2}>
              {videoList?.map((clip: any) => (
                <Grid2
                  onClick={() => {
                    if (hasPermition()) {
                      navigate(
                        PageRoutes.ShortView.replace(":id", clip.ID) +
                          (id &&
                            type &&
                            `?channel=${channel}&type=${type}&type_name=${type_name}&channel_id=${id}&type=${type}`)
                      );
                    }
                  }}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  key={clip.ID}
                >
                  <ShortContentCard clip={clip} />
                </Grid2>
              ))}
            </Grid2>
          </Box>
        </InfiniteScroll>
      </Box>
      {!hasPermition() && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            right: 0,
            width: 1,
            bottom: "50%",
            left: 0,
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              color: configData.primary,
              fontSize: 18,
              fontWeight: 600,
            }}
            variant="body1"
          >
            {!auth.AccessToken ? (
              <Button
                variant="contained"
                onClick={() => navigate(PageRoutes.Login)}
              >
                Log in to see the highlights
              </Button>
            ) : (
              "This feature is only available to insiders"
            )}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default FilterShortFormContent;
