import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import React, { useCallback, useEffect, useState } from "react";
import HttpService from "../../../hooks/Https-services";
import useConfig from "../../../hooks/useConfig";
import { APIRoutes } from "../../../utils/constants";
import TeamBox from "./TeamBox";
import { toast } from "react-toastify";
import _ from "lodash";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const FavoriteTeam = () => {
  const configData = useConfig();
  const [value, setValue] = React.useState<any>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    const findChannelId: any = channelOption?.find(
      (el: any, index: any) => index == newValue
    );
    FetchTeams(findChannelId?.channel_id);
  };
  const [teamOption, setTeamOption] = useState<any>([]);
  const [selectedTeams, setSelectedTeam] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [channelOption, setChannelOption] = useState([]);
  const [teamName, setTeamName] = useState("");
  const FetchChannels = async () => {
    try {
      const result = await HttpService.getapi(
        APIRoutes.ChannelOption.replace(":id", configData.customer)
      );
      const channel_id = result?.data?.data[0]?.channel_id;
      if (channel_id) {
        FetchTeams(channel_id);
      }

      setChannelOption(result.data.data);
    } catch (error) {}
  };
  const FetchTeams = async (channel_id: any) => {
    setIsLoading(true);
    try {
      const result = await HttpService.getapi(
        APIRoutes.Team.replace(":id", channel_id || "").replace(
          ":customer",
          configData.customer
        )
      );

      setTeamOption(result.data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    } finally {
    }
  };
  const createOrUpdateUserFavouriteTeam = async () => {
    try {
      if (selectedTeams?.length === 0) {
        toast.error("Please select team");
        return;
      }
      const res = await HttpService.post(
        APIRoutes.CreateOrUpdateUserFavouriteTeam,
        { teams: selectedTeams }
      );
      toast.success("Teams saved successfully");
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };
  const FetchUserFaveouriteTeam = async () => {
    try {
      const res = await HttpService.get(APIRoutes.GetUsersFavouriteTeam);
      if (res?.data?.data) {
        setSelectedTeam(res?.data?.data?.UserFavouriteTeam);
      }
    } catch (error) {}
  };

  const debouncedFetchUserFavouriteTeam = useCallback(
    _.debounce(FetchUserFaveouriteTeam, 500),
    []
  );
  useEffect(() => {
    FetchChannels();
  }, []);
  useEffect(() => {
    debouncedFetchUserFavouriteTeam();

    // Cleanup function to cancel the previous debounced function
  }, [teamName]);
  return (
    <Box>
      <Box>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          gap={1}
          justifyContent={"space-between"}
          alignItems={{ xs: "start", sm: "center" }}
        >
          <Stack sx={{ flexGrow: 1, width: { xs: 1, sm: "auto" } }}>
            <Typography variant="h1">Favorite Teams</Typography>
            <Typography
              variant="body1"
              sx={{
                fontStyle: "normal",
                textTransform: "none",
              }}
            >
              (Choose up to 5)
            </Typography>
            <Box sx={{ flexGrow: 1 }}>
              <TextField
                sx={{ mt: 1, width: { xs: 1, sm: "auto" } }}
                size="small"
                data-v-ee71dd48=""
                type="text"
                name="team_name"
                id="team_name"
                placeholder="Search"
                value={teamName}
                onChange={(e) => {
                  setTeamName(e?.target?.value);
                }}
              />
            </Box>
          </Stack>
          <Stack direction={{ xs: "row" }} gap={1.5}>
            <Box>
              <button
                onClick={createOrUpdateUserFavouriteTeam}
                className={configData.buttonCommonClass}
              >
                Save
              </button>
            </Box>
            <Box>
              <button
                onClick={() => setSelectedTeam([])}
                style={{ backgroundColor: "grey" }}
                className={configData.buttonCommonClass}
              >
                Cancel
              </button>
            </Box>
          </Stack>
        </Stack>

        <Box
          sx={{
            py: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                borderBottom: 1,
                width: 1,
                borderColor: "divider",
              }}
            >
              <Tabs
                value={value}
                variant="scrollable"
                scrollButtons="auto"
                onChange={handleChange}
                aria-label="scrollable auto tabs example"
              >
                {channelOption?.map((el: any, index: any) => (
                  <Tab
                    sx={{
                      whiteSpace: "nowrap",
                      maxWidth: "100% !important",
                    }}
                    label={el?.channel_name}
                    {...a11yProps(index)}
                  />
                ))}
              </Tabs>
            </Box>

            {channelOption?.map((el: any, index) => {
              return (
                <CustomTabPanel value={value} index={index}>
                  {isLoading ? (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Grid container spacing={3}>
                      {teamOption
                        ?.filter((ele: any) =>
                          (ele?.team_name + " " + ele?.team_mascot)
                            ?.toLowerCase()
                            ?.includes(teamName?.toLowerCase())
                        )
                        ?.map((ele: any) => (
                          <Grid item xs={12} sm={6} md={3}>
                            <TeamBox
                              el={ele}
                              setSelectedTeam={setSelectedTeam}
                              selectedTeams={selectedTeams}
                            />
                          </Grid>
                        ))}
                    </Grid>
                  )}
                </CustomTabPanel>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default FavoriteTeam;
