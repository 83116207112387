import { Box, Typography } from "@mui/material";
import React, { ReactComponentElement, ReactElement } from "react";
import NAHL from "../../../assets/img/nahl.png";
import NOTEAM from "../../../assets/img/noteam.png";
import PageRoutes, { APIRoutes } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import momentTimezone from "moment-timezone";
import "./index.css";
import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";
import useConfig from "../../../hooks/useConfig";
import DialogBox from "../DialogBox";
import HttpService from "../../../hooks/Https-services";
import styled from "styled-components";
import EllipsisContent from "../EllipsisContent";
import { Mic } from "@mui/icons-material";
const userTimeZone = momentTimezone.tz.guess();

const StyledImage = styled.img`
  width: 100%;
`;

const GameCardV2 = ({
  subtitle,
  game,
  auth,
  isSubscribed,
  subscriptionDetails,
  gameStatus,
}: {
  gameStatus: ReactElement;
  subtitle: string;
  game: any;
  auth: any;
  isSubscribed: boolean;
  subscriptionDetails: any;
}) => {
  const navigate = useNavigate();
  const configData = useConfig();
  const gaEventTracker = useAnalyticsEventTracker("upcoming-events");
  const handleNavigate = (game: any) => {
    gaEventTracker("watch", `watch${game?.game_id}`);
    navigate(PageRoutes.Watch.replace(":gameId", game?.game_id));
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const checkHasShowName = () => {
    console.log(game?.card_image_url, game.gameId);
    const url = new URL(game?.card_image_url); // Create a URL object
    const isShowImage = url.searchParams.get("showNames"); // Get the value of isShowImage
    if (isShowImage === "false" || !isShowImage) {
      return false;
    } else if (isShowImage === "true") {
      return true;
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const checkGameTimeIsBeetween48Hour = (time: any) => {
    const formatedTime = momentTimezone
      .tz(time, "YYYY-MM-DD hh:mm:ss.SSSSSS a", "America/New_York")
      .tz(userTimeZone);

    const currentTime = moment(moment(), "YYYY-MM-DD hh:mm:ss.SSSSSS a");
    const futureTime = moment(
      moment().add(2, "days"),
      "YYYY-MM-DD hh:mm:ss.SSSSSS a"
    );

    if (formatedTime.isBefore(futureTime)) {
      return true;
    } else {
      return false;
    }
  };
  // You started watching Gold at Blue from 2022-07-11 @ 8:30PM EDT.
  const handleSubmitLogs = async (game: any) => {
    try {
      const data = {
        game_id: game?.game_id,
        message: `You started watching ${game.away_team} at ${
          game.home_team
        } from ${moment(
          game.game_date_time,
          "YYYY-MM-DD hh:mm:ss.SSSSSS a Z"
        ).format("YYYY-MM-DD @ hh:mm A")}`,
        eventTyle: "GAME_LOGS",
      };

      const res = await HttpService.post(APIRoutes.GameLogsAdd, data);
    } catch (error) {}
  };
  const edtDateTime = new Date(game?.game_date_time);
  return (
    <Box
      sx={{
        display: "flex",
        width: 1,

        justifyContent: { xs: "center", xl: "flex-start" },
      }}
      id={game?.game_id}
    >
      <Box sx={{ flexGrow: 1, maxWidth: 300 }}>
        <Box sx={{ py: 0.5 }}>
          <EllipsisContent
            variant="body2"
            maxWidth={"100%"}
            text={momentTimezone
              .tz(
                game.game_date_time,
                "YYYY-MM-DD hh:mm:ss.SSSSSS a Z",
                "America/New_York"
              )
              .tz(userTimeZone)
              .format("MMM DD YYYY hh:mm A z")}
            sx={{
              textTransform: "uppercase",
              fontStyle: "normal",
              fontWeight: 500,
              color: "#636363",
            }}
          />
        </Box>
        <Box
          sx={{
            width: { xs: 1 },
            paddingTop: "5px",
            borderRadius: "4px",
            height: 115,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            maxWidth: { xs: "auto", sm: 330 },
            background: `linear-gradient(90deg,  ${
              game?.away_team_primary_color || configData.secondary
            } 50%,${game?.home_team_primary_color || configData.primary} 50%)`,
            backgroundImage:
              game?.card_image_url && `url(${game?.card_image_url})`,
            backgroundSize: "100% 100%",
          }}
          className="card-update"
        >
          {!!game?.card_image_url ? (
            <Box
              sx={{
                height: 80,
                display: "flex",
                justifyContent: "center",
                p: 0.5,
              }}
            ></Box>
          ) : (
            <Box
              sx={{
                height: 80,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 10,
              }}
            >
              <Box
                style={{
                  width: "80px",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                {game.away_team_logo ? (
                  <img
                    className="teamLogo"
                    width="90%"
                    style={{ maxHeight: "64px" }}
                    src={game.away_team_logo}
                    alt=""
                  />
                ) : (
                  <img
                    className="teamLogo"
                    width="90%"
                    style={{ maxHeight: "64px" }}
                    src={NOTEAM}
                    alt=""
                  />
                )}
              </Box>

              <Box style={{ width: "80px" }}>
                {game.home_team_logo ? (
                  <img
                    className="teamLogo"
                    width="90%"
                    style={{ maxHeight: "64px" }}
                    src={game.home_team_logo}
                    alt=""
                  />
                ) : (
                  <img
                    className="teamLogo"
                    width="90%"
                    style={{ maxHeight: "64px" }}
                    src={NOTEAM}
                    alt=""
                  />
                )}
              </Box>
            </Box>
          )}

          <Box sx={{ alignItems: "flex-end" }} className="footer-card">
            <Box sx={{ display: "flex", gap: 1 }}>
              {gameStatus}
              {/* {game?.production_audio_type_id === 2 &&
                ["lakeplacid"].includes(configData.name) && (
                  <Mic sx={{ color: "#fff" }} />
                )} */}
            </Box>

            {game?.game_status == 90 ? (
              <Typography
                variant="body2"
                sx={{ color: configData?.tertiary, fontWeight: 600 }}
              >
                Broadcast Canceled
              </Typography>
            ) : game?.game_status == 95 ? (
              <Typography
                variant="body2"
                sx={{ color: configData?.tertiary, fontWeight: 600 }}
              >
                Game Canceled
              </Typography>
            ) : (
              <Box>
                {(isSubscribed || game?.subscribe) &&
                !subscriptionDetails?.pause_collection ? (
                  <button
                    className="card-footer-button"
                    style={{
                      color: configData.primary,
                      fontStyle: "normal",
                    }}
                    onClick={() => {
                      handleNavigate(game);
                      handleSubmitLogs(game);
                    }}
                  >
                    WATCH
                  </button>
                ) : !auth?.AccessToken && game.monetization_type == 1 ? (
                  <button
                    className="card-footer-button"
                    style={{
                      whiteSpace: "nowrap",
                      width: "auto",
                      color: configData.primary,
                      fontStyle: "normal",
                    }}
                    onClick={() => {
                      navigate(PageRoutes.Login);
                    }}
                  >
                    WATCH FREE
                  </button>
                ) : game.monetization_type == 0 ? (
                  <button
                    style={{
                      color: configData.primary,
                      fontStyle: "normal",
                    }}
                    className="card-footer-button"
                    onClick={() => {
                      if (auth.AccessToken) {
                        checkGameTimeIsBeetween48Hour(game.game_date_time)
                          ? navigate(
                              `${PageRoutes.Packages}?id=${game?.game_id}`
                            )
                          : handleClickOpen();
                      } else {
                        navigate(PageRoutes.Login);
                      }
                    }}
                  >
                    BUY EVENT
                  </button>
                ) : (
                  <button
                    style={{
                      color: configData.primary,
                      fontStyle: "normal",
                    }}
                    className="card-footer-button"
                    onClick={() => {
                      handleNavigate(game);
                      handleSubmitLogs(game);
                    }}
                  >
                    WATCH
                  </button>
                )}
              </Box>
            )}
            <DialogBox
              open={open}
              handleClose={handleClose}
              buttonAction={() => handleClose()}
              buttonText={"Close"}
              content={
                "You can only buy a single game within 48 hours of game time."
              }
            />
          </Box>
        </Box>
        <Box sx={{ py: 0.5 }}>
          {game?.card_description ? (
            <EllipsisContent
              variant="caption"
              maxWidth={"100%"}
              text={game?.card_description}
              sx={{
                textTransform: "uppercase",

                fontStyle: "normal",
                fontWeight: 500,
              }}
            />
          ) : (
            <Typography
              variant="caption"
              sx={{
                fontStyle: "normal",
                fontWeight: 500,
              }}
            >
              REGULAR SEASON GAME
            </Typography>
          )}
        </Box>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Box>
            {game?.card_logo ? (
              <Box>
                <img
                  src={game?.card_logo}
                  alt=""
                  style={{ width: "60px", height: "40px" }}
                />
              </Box>
            ) : (
              <img
                src={NAHL}
                alt=""
                style={{ width: "60px", height: "40px", visibility: "hidden" }}
              />
            )}
          </Box>
          <Box>
            {!!game?.card_image_url && !checkHasShowName() ? (
              <Box></Box>
            ) : (
              <Box>
                <EllipsisContent
                  maxWidth={220}
                  text={`${game.away_team} ${game.away_team_mascot}`}
                  variant="body2"
                  sx={{
                    textTransform: "uppercase",

                    fontStyle: "normal",
                    fontWeight: 500,
                  }}
                />
                <EllipsisContent
                  maxWidth={220}
                  text={`${game.home_team} ${game.home_team_mascot}`}
                  variant="body2"
                  sx={{
                    textTransform: "uppercase",

                    fontStyle: "normal",
                    fontWeight: 500,
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GameCardV2;
