import {
  Box,
  Button,
  Grid,
  Pagination,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import useConfig from "../../../hooks/useConfig";
import useCommonDetails from "../../../Common/Hooks/useCommonDetails";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import PageRoutes, {
  APIRoutes,
  INSIDER_PLAN_ID,
  UserRoles,
} from "../../../utils/constants";
import HttpService from "../../../hooks/Https-services";
import HighlightPlayer from "../../HighlightPlayer/Player";
import MatchCard from "../../PersonalChannel/MatchCard";
import HighlightList from "../../HomePage/HighlightList";
import ShortContentCard from "../ShortContentCard";
import Grid2 from "@mui/material/Unstable_Grid2";
import HighlightListV2 from "../HighlightList";
import moment from "moment";
import SocialShare, { MetaTags } from "../../../Common/Component/SocialShare";

const Highlights = () => {
  const playerRef: any = React.useRef(null);
  const [pageData, setPageData] = useState({} as any);
  const [planDetails, setPlanDetails] = useState({} as any);
  const [subscriptionDetails, setSubscriptionDetails] = useState({} as any);
  const [isSkeletonList, setIsSkeletonList] = useState(true);
  const [videoList, setVideoList] = useState<any[]>([]);
  const [currentVideo, setCurrentVideo] = useState({} as any);
  const configData = useConfig();
  const userDetails = useCommonDetails();
  const navigate = useNavigate();
  const { id }: any = useParams();
  const location = useLocation();
  const [page, setPage] = React.useState<any>(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const GetHighLightsByID = useCallback(async () => {
    try {
      if (
        ((planDetails?.plan_type_id == INSIDER_PLAN_ID &&
          !planDetails?.subscription?.pause_collection) ||
          [
            UserRoles.HTP_ADMIN,
            UserRoles.LEAGUE_ADMIN,
            UserRoles.FREE_WATCH_D,
          ].includes(userDetails?.UserRoleID)) &&
        ["nahl", "dev", "ssm", "usah"].includes(configData.name) &&
        id
      ) {
        const res = await HttpService.get(
          `${APIRoutes.GetWhizzardByID.replace(":id", id)}`
        );
        if (res) {
          setCurrentVideo(res?.data?.data);
        }
        setIsSkeletonList(false);
      } else {
        setIsSkeletonList(false);
      }
    } catch (error) {
      setIsSkeletonList(false);
    }
  }, [id, planDetails]);
  const GetUserSubscriptionData = useCallback(async () => {
    try {
      const res = await HttpService.get(APIRoutes.GetSubscriptionDetials);

      if (res?.data?.data) {
        setPlanDetails(res?.data?.data);
        setSubscriptionDetails(res?.data?.data?.subscription);
        return res?.data?.data;
      }
    } catch (error) {}
  }, []);
  useEffect(() => {
    GetUserSubscriptionData();
  }, [GetUserSubscriptionData]);
  useEffect(() => {
    GetHighLightsByID();
  }, [GetHighLightsByID]);
  return (
    <Box sx={{ p: 5 }}>
      {/* {currentVideo && (
        <MetaTags
          title={currentVideo.Title || "Video Highlight"}
          description={currentVideo.Description || "Watch this video highlight"}
          image={currentVideo.ThumbnailUrl || ""}
          url={window.location.href}
        />
      )} */}
      {((planDetails?.plan_type_id == INSIDER_PLAN_ID &&
        !planDetails?.subscription?.pause_collection) ||
        [
          UserRoles.HTP_ADMIN,
          UserRoles.LEAGUE_ADMIN,
          UserRoles.FREE_WATCH_D,
        ].includes(userDetails?.UserRoleID)) &&
      ["nahl", "dev", "ssm", "usah"].includes(configData.name) ? (
        <Grid2 sx={{ my: 2 }} container spacing={2}>
          <Grid2 xs={12} md={12}>
            <Box>
              <Box
                sx={{
                  mt: 1,
                  width: { xs: 1, md: 0.8 },
                  maxWidth: 1440,
                  textAlign: "center",
                  mx: "auto",
                  px: 1,
                }}
              >
                <HighlightPlayer
                  playerRef={playerRef}
                  currentVideoData={currentVideo}
                  setCurrentVideo={setCurrentVideo}
                  videoList={videoList}
                  pageData={pageData}
                  videoUrl={currentVideo?.HighlightUrl}
                  setPage={() => {}}
                  page={page}
                />
              </Box>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: { xs: "flex-start", md: "center" },
                  flexDirection: { xs: "column", md: "row" },
                  gap: 2,
                  width: { xs: 1, xl: 0.8 },
                  maxWidth: 1440,
                  px: 1,
                  mx: "auto",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontStyle: "normal",
                      textTransform: "none",
                    }}
                    variant="h3"
                  >
                    {currentVideo?.Description}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontStyle: "normal",
                      textTransform: "none",
                    }}
                    variant="body1"
                  >
                    {currentVideo?.Title}
                  </Typography>

                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontStyle: "normal",
                      color: "#474747",
                      textTransform: "none",
                    }}
                    variant="body2"
                  >
                    {moment(currentVideo?.createdAt).fromNow()}
                  </Typography>
                </Box>
                <SocialShare
                  url={window.location.href}
                  title={currentVideo?.Title || ""}
                />
              </Box>
            </Box>
          </Grid2>
          <Grid2 container spacing={2} xs={12}>
            <HighlightListV2
              planDetails={planDetails}
              subscriptionDetails={subscriptionDetails}
            />
          </Grid2>
        </Grid2>
      ) : (
        <Box sx={{ textAlign: "center" }}>
          <Button
            onClick={() => navigate(PageRoutes.Packages)}
            variant="contained"
          >
            Buy Insider Plan
          </Button>
        </Box>
      )}
    </Box>
  );
};
export default Highlights;
