import {
  Box,
  Button,
  Container,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Slider from "react-slick";
import styles from "./styles.module.css";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useConfig from "../../../hooks/useConfig";
import HttpService from "../../../hooks/Https-services";
import PageRoutes, {
  APIRoutes,
  INSIDER_PLAN_ID,
  UserRoles,
} from "../../../utils/constants";
import useCommonDetails from "../../../Common/Hooks/useCommonDetails";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getCurrentAuth } from "../../../hooks/http-server";
import ShortContentCard from "../ShortContentCard";
const HighlightListV2 = ({ planDetails, subscriptionDetails }: any) => {
  const configData = useConfig();
  const auth = getCurrentAuth();
  const [searchParams] = useSearchParams();
  const channel = searchParams.get("channel");
  const type = searchParams.get("type");
  const type_name = searchParams.get("type_name");
  const channel_id = searchParams.get("channel_id");
  const userDetails = useCommonDetails();
  const navigate = useNavigate();
  const [highlights, setHighlights] = useState([]);
  const { id } = useParams();
  const hasPermition = () => {
    if (
      (planDetails?.plan_type_id == INSIDER_PLAN_ID &&
        !subscriptionDetails?.pause_collection) ||
      [
        UserRoles.HTP_ADMIN,
        UserRoles.LEAGUE_ADMIN,
        UserRoles.FREE_WATCH_D,
      ].includes(userDetails?.UserRoleID)
    ) {
      return true;
    } else {
      return false;
    }
  };
  const GetHighLights = useCallback(async () => {
    try {
      let query = {};
      if (channel_id) {
        query = {
          channel_id: channel_id,
        };
      }

      if (type) {
        query = {
          ...query,
          type: type,
        };
      }
      const res = await HttpService.get(
        APIRoutes.GetWhizzardsV2 + "?" + new URLSearchParams(query).toString()
      );
      if (res) {
        setHighlights(res.data?.data?.wizzards);
      }
    } catch (error) {}
  }, []);
  useEffect(() => {
    GetHighLights();
  }, [GetHighLights]);
  const PreviousBtn = (props: any) => {
    // console.log(props);
    const { className, onClick } = props;
    return (
      <Box
        className={className}
        sx={{ position: "absolute", top: "50%", bottom: "50%" }}
      >
        <IconButton
          onClick={onClick}
          sx={{
            bgcolor: configData.primary,
            border: "1px solid #E7E7E7",
            p: 0.5,
            zIndex: 10,
            ":hover": {
              bgcolor: configData.primary,
            },
          }}
        >
          <ArrowBackIcon
            sx={{ fontSize: "16px", color: configData.tertiary }}
          />
        </IconButton>
      </Box>
    );
  };
  const NextBtn = (props: any) => {
    const { className, onClick } = props;
    return (
      <Box className={className} sx={{ position: "absolute", top: "50%" }}>
        <IconButton
          onClick={onClick}
          sx={{
            border: "1px solid #E7E7E7",
            rotate: "180deg",
            p: 0.5,
            bgcolor: configData.primary,
            ":hover": {
              bgcolor: configData.primary,
            },
          }}
        >
          <ArrowBackIcon
            sx={{ fontSize: "16px", color: configData.tertiary }}
          />
        </IconButton>
      </Box>
    );
  };
  const isMobile = useMediaQuery((theme: any) =>
    theme.breakpoints.down("1440")
  );
  const [sliderRef, setSliderRef] = useState<any>(null);
  //  xs: 0,
  //       sm: 567,
  //       md: 768,
  //       lg: 992,
  //       xl: 1440,
  const sliderSettings = {
    slidesToShow: 4,
    dots: true,
    autoplay: false,
    autoplaySpeed: 2500,
    slidesToScroll: 4,
    infinite: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: highlights?.length > 3 ? 3 : highlights?.length,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
    ],
  };
  return (
    <Container
      maxWidth="xl"
      sx={{ position: "relative", pb: 5, px: "0px !important" }}
    >
      <Box
        sx={{
          opacity: !hasPermition() ? 0.3 : 1,
          backgroundColor: !hasPermition() ? configData.primary : "",
          filter: !hasPermition() ? "blur(1px)" : "",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            textTransform: "none",
            fontStyle: "normal",
            fontWeight: 600,
            px: 1,
            py: 2,
          }}
        >
          Must Watch
        </Typography>
        {!!highlights?.filter((highlight: any) => highlight.ID != id)
          ?.length ? (
          <Box sx={{ mb: 2 }}>
            <Box>
              <Slider
                ref={setSliderRef}
                {...sliderSettings}
                prevArrow={<PreviousBtn />}
                nextArrow={<NextBtn />}
                dotsClass="slick-dots custom-indicator"
              >
                {highlights
                  ?.filter((highlight: any) => highlight.ID != id)
                  .map((highlight: any) => (
                    <Box
                      onClick={() =>
                        navigate(
                          PageRoutes.ShortView.replace(":id", highlight.ID)
                        )
                      }
                    >
                      <ShortContentCard clip={highlight} />
                    </Box>
                  ))}
                {highlights?.length === 3 && !isMobile && <Box></Box>}
                {highlights?.length === 2 &&
                  !isMobile &&
                  new Array(2).fill(0).map((el) => <Box></Box>)}
                {highlights?.length === 1 &&
                  !isMobile &&
                  new Array(3).fill(0).map((el) => <Box></Box>)}
              </Slider>
            </Box>
          </Box>
        ) : (
          <Box sx={{ textAlign: "start", px: 3 }}>
            <Typography
              sx={{
                textTransform: "none",
                fontStyle: "normal",
              }}
            >
              There are no highlights right now
            </Typography>
          </Box>
        )}
      </Box>

      {!hasPermition() && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            right: 0,
            width: 1,
            bottom: "50%",
            left: 0,
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              color: configData.primary,
              fontSize: 18,
              fontWeight: 600,
            }}
            variant="body1"
          >
            {!auth.AccessToken ? (
              <Button
                variant="contained"
                onClick={() => navigate(PageRoutes.Login)}
              >
                Log in to see the highlights
              </Button>
            ) : (
              "This feature is only available to insiders"
            )}
          </Typography>
        </Box>
      )}
    </Container>
  );
};
export default HighlightListV2;
