import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import useConfig from "../../../hooks/useConfig";
import moment from "moment";
import { Download } from "@mui/icons-material";
import HttpService from "../../../hooks/Https-services";
import PageRoutes, {
  APIRoutes,
  INSIDER_PLAN_ID,
} from "../../../utils/constants";
import { toast } from "react-toastify";
import DialogBox from "../../../Common/Component/DialogBox";
import { Link, useParams } from "react-router-dom";
import useCommonDetails from "../../../Common/Hooks/useCommonDetails";

const MatchCardv2 = (props: any) => {
  const {
    active,
    isHighLight = false,
    thubmnails,
    gameDetails,
    isDownloadAll,
    el,
    subscriptionDetails,
    setIsDownloadAll,
  } = props;
  const configData = useConfig();
  const [currenClip, setCurrentClip] = useState<any>(null);
  const [isDownload, setIsDownload] = useState(false);
  const [open, setOpen] = React.useState(false);
  const userDetails = useCommonDetails();
  const { gameId }: any = useParams;
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDownloadClick = async (event: any) => {
    event.stopPropagation();
    try {
      if (currenClip?.Status == "COMPLETE" && currenClip?.DownloadUrl) {
        const a = document.createElement("a");
        a.href = currenClip?.DownloadUrl;
        a.target = "_blank";
        a.download = `${currenClip.TaskID}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        setIsDownloadAll(true);

        setIsDownload(true);

        const res = await HttpService.post(APIRoutes.DownloadClipsV2, {
          item: {
            duration: el.duration,
            id: parseInt(el.game_event_id),
          },
          game_details: {
            away_team: el.away_team_name,
            game_date_time: moment(el.game_date),
            home_team: el.home_team_name,
          },
          other_details: {
            clip_id: el.game_event_id,
            type: el.event_type?.split("_")?.join(" "),
            game_id: gameDetails?.game_id,
            game_id_tpe: gameDetails?.game_id_tpe,
          },
        });
        toast.info(
          "Your download has been requested.  You will receive an email when it is complete."
        );
        setIsDownloadAll(false);
      }
    } catch (error: any) {
      setIsDownload(false);
      setIsDownloadAll(false);
      toast.error(error.response.data.message);
    }
  };

  const findCurrent = useCallback(() => {
    const clip = props.allDownloadVideo?.find(
      (element: any) => element?.ClipID == el.game_event_id
    );
    if (clip) {
      setCurrentClip(clip);
    } else {
      setCurrentClip(null);
    }
  }, [el.id, props.allDownloadVideo]);

  useEffect(() => {
    findCurrent();
  }, [findCurrent]);
  return (
    <Box
      sx={{
        "&:hover": { cursor: "pointer" },
        border: active ? "2px solid red" : "",
      }}
    >
      <Box sx={{ border: "1px solid #ccc", bgcolor: "#f5f5f5", py: 0.5 }}>
        {isHighLight ? (
          <Stack
            width={1}
            direction={"row"}
            justifyContent={"space-between"}
            sx={{ px: 1 }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "14px",
                fontStyle: "normal",
                color: configData.primary,
                fontWeight: "700",
              }}
            >
              PERIOD {el?.period}
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "14px",
                fontStyle: "normal",
                color: configData.primary,
                fontWeight: "700",
              }}
            >
              {el?.event_type}
            </Typography>
          </Stack>
        ) : (
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "14px",
              fontStyle: "normal",
              color: configData.primary,
              fontWeight: "700",
            }}
          >
            {el?.event_type}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          border: "1px solid #ccc",
          bgcolor: configData.tertiary,
          padding: 1,
          // textAlign: "center",
          position: "relative",
          display: "flex",
          alignItems: "center",
          textAlign: "start",
          justifyContent: "space-between",
        }}
      >
        {isHighLight ? (
          <Typography
            fontWeight="700"
            sx={{
              textTransform: "none",
              fontSize: "13px",
              fontStyle: "normal",
            }}
          >
            {`${el?.player_jersey}   ${el?.player_first_name} ${el?.player_last_name} (${el?.player_position})`}
            <Typography
              fontWeight="600"
              sx={{
                textTransform: "none",
                fontSize: "12px",
                fontStyle: "normal",
              }}
            >
              {el?.team_name}
            </Typography>
          </Typography>
        ) : (
          <Typography
            fontWeight="700"
            sx={{
              textTransform: "none",
              fontSize: "13px",
              fontStyle: "normal",
            }}
          >
            {`${el.away_team_name} VS ${el.home_team_name}`}
            <Typography
              fontWeight="600"
              sx={{
                textTransform: "none",
                fontSize: "12px",
                fontStyle: "normal",
              }}
            >
              {moment(el?.game_date).format("MMMM DD, YYYY")}
            </Typography>
          </Typography>
        )}

        {process.env.REACT_APP_FEATURE === "true" && (
          <IconButton
            color={currenClip?.Status == "COMPLETE" ? "success" : "primary"}
            disabled={
              currenClip?.Status === "SUBMITTED" ||
              currenClip?.Status === "ERROR" ||
              currenClip?.Status == "PENDING" ||
              isDownload ||
              (currenClip?.Status !== "COMPLETE" && isDownloadAll)
            }
            onClick={
              subscriptionDetails?.plan_type_id == INSIDER_PLAN_ID ||
              userDetails?.UserRole?.Permissions?.includes("download")
                ? handleDownloadClick
                : handleClickOpen
            }
          >
            <Download />
          </IconButton>
        )}

        <DialogBox
          buttonText={""}
          handleClose={handleClose}
          open={open}
          content={
            <Typography sx={{ textTransform: "none", fontStyle: "normal" }}>
              It looks like you're trying to initiate a download. Downloads are
              limited to subscribers of our Insider Plan. Head over to the{" "}
              <Link to={PageRoutes.Packages}>Plans</Link> tab to upgrade to the
              Insider Plan and then try your download again.
            </Typography>
          }
          buttonActionSecondary={handleClose}
          butttonTextSecondary={"Cancel"}
        />
      </Box>
    </Box>
  );
};

export default MatchCardv2;
