import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Disable browser's default scroll restoration
    if ("scrollRestoration" in window?.history) {
      window.history.scrollRestoration = "manual";
    }

    // Scroll to top on every navigation
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
