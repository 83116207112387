import { Box, Container, Skeleton, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import PageRoutes, { APIRoutes } from "../../../utils/constants";
import ShortContentCard from "../ShortContentCard";
import { useCallback, useState, useEffect } from "react";
import HttpService from "../../../hooks/Https-services";
import HighLightCard from "../../HomePage/HighlightList/HighLightCard";
import React from "react";

const LatestVideo = ({ hasPermition }: any) => {
  const navigate = useNavigate();
  const [videoList, setVideoList] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  const GetClips = useCallback(async () => {
    setIsLoading(true);
    try {
      console.log("first");
      const response = await HttpService.get(
        APIRoutes.GetWhizzardsV2 + `?page=${1}&limit=8`
      );
      setVideoList(response.data?.data?.wizzards);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    GetClips();
  }, [GetClips]);
  if (isLoading) {
    return (
      <Container
        maxWidth="xl"
        sx={{ position: "relative", px: "0px !important" }}
      >
        <Grid2 container spacing={2}>
          <Grid2 xs={12} sm={12} md={6} lg={4} xl={3}>
            <Skeleton variant="rectangular" height={250} />
          </Grid2>
          <Grid2 xs={12} sm={12} md={6} lg={4} xl={3}>
            <Skeleton variant="rectangular" height={250} />
          </Grid2>
          <Grid2 xs={12} sm={12} md={6} lg={4} xl={3}>
            <Skeleton variant="rectangular" height={250} />
          </Grid2>
          <Grid2 xs={12} sm={12} md={6} lg={4} xl={3}>
            <Skeleton variant="rectangular" height={250} />
          </Grid2>
          <Grid2 xs={12} sm={12} md={6} lg={4} xl={3}>
            <Skeleton variant="rectangular" height={250} />
          </Grid2>
          <Grid2 xs={12} sm={12} md={6} lg={4} xl={3}>
            <Skeleton variant="rectangular" height={250} />
          </Grid2>
          <Grid2 xs={12} sm={12} md={6} lg={4} xl={3}>
            <Skeleton variant="rectangular" height={250} />
          </Grid2>
          <Grid2 xs={12} sm={12} md={6} lg={4} xl={3}>
            <Skeleton variant="rectangular" height={250} />
          </Grid2>
        </Grid2>
      </Container>
    );
  }
  return (
    <Container maxWidth="xl" sx={{ p: "8px !important" }}>
      <Box>
        <Typography variant="h3" sx={{ fontWeight: 600 }}>
          Latest Videos
        </Typography>
        <Box
          sx={{
            mt: 2,
          }}
        >
          <Grid2 container spacing={2} rowSpacing={3} columnSpacing={2}>
            {videoList?.map((clip: any) => (
              <Grid2
                onClick={() => {
                  if (hasPermition()) {
                    navigate(PageRoutes.ShortView.replace(":id", clip.ID));
                  }
                }}
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={3}
                key={clip.ID}
              >
                <ShortContentCard showDate={false} clip={clip} />
              </Grid2>
            ))}
          </Grid2>
        </Box>
      </Box>
    </Container>
  );
};

export default LatestVideo;
