import {
  Box,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import HttpService from "../../../../../hooks/Https-services";
import { APIRoutes } from "../../../../../utils/constants";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import moment from "moment";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

const UserViewershipReports = () => {
  const [viewerShip, setViewerShip] = useState();
  const [month, setMonth] = React.useState(moment().format("MM"));

  const [allReports, setAllReports] = React.useState({} as any);
  const [keyword, setKeyword] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDownloading, setIsDownloading] = React.useState(false);
  const navigate = useNavigate();
  const { id }: any = useParams();
  // Avoid a layout jump when reaching the last page with empty rows.
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allReports?.total) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const GetUserViewerShipReport = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await HttpService.get(
        `${APIRoutes.GetUserViewerShipReport.replace(":id", id)}?page=${
          page + 1
        }&limit=${rowsPerPage}`
      );
      setAllReports(response.data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [page, rowsPerPage, id]);
  useEffect(() => {
    GetUserViewerShipReport();
  }, [GetUserViewerShipReport]);
  return (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: "normal",
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Game ID
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: "normal",
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Game Date
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: "normal",
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Away Team
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: "normal",
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Home Team
                </Typography>
              </TableCell>

              <TableCell align="left">
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: "normal",
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Total Minute Watched
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableBody>
              {new Array(10).fill(0).map((el) => {
                return (
                  <TableRow>
                    <TableCell>
                      <Skeleton variant={"rounded"} sx={{ p: 2 }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant={"rounded"} sx={{ p: 2 }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant={"rounded"} sx={{ p: 2 }} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant={"rounded"} sx={{ p: 2 }} />
                    </TableCell>

                    <TableCell>
                      <Skeleton variant={"rounded"} sx={{ p: 2 }} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <TableBody>
              {allReports?.rows?.map((row: any) => (
                <TableRow key={row.GameID}>
                  <TableCell component="th" scope="row">
                    <Typography
                      variant="body2"
                      sx={{ fontStyle: "normal", textTransform: "none" }}
                    >
                      {row.GameID}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    <Typography
                      variant="body2"
                      sx={{ fontStyle: "normal", textTransform: "none" }}
                    >
                      {moment(row?.game_date_time?.value).format(
                        "DD MMM YYYY hh:mm A"
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    <Typography
                      variant="body2"
                      sx={{ fontStyle: "normal", textTransform: "none" }}
                    >
                      {row?.away_team}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    <Typography
                      variant="body2"
                      sx={{ fontStyle: "normal", textTransform: "none" }}
                    >
                      {row?.home_team}
                    </Typography>
                  </TableCell>

                  <TableCell style={{ width: 160 }} align="left">
                    <Typography
                      variant="body2"
                      sx={{
                        fontStyle: "normal",
                        textTransform: "none",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {row?.minutesWatched}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        colSpan={3}
        rowsPerPageOptions={[10, 20, 30]}
        count={allReports.total}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </Box>
  );
};
export default UserViewershipReports;
